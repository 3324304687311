import { apiService, Center } from '@efacity/frontend-shared';
import { useCallback, useState } from 'react';
import { PaginationOptions } from '../../components/Table/Table';
import { PaginatedResult } from '@efacity/common';
import { showNotification } from '@efacity/frontend-next-shared/notifications';

export interface CentersState {
  centers: Center[];
  loadingCentersList: boolean;
  total: number;
}

export const defaultCentersState: CentersState = {
  centers: [],
  loadingCentersList: false,
  total: 0
};

export const useCentersLoader = (url: string) => {
  const [centersState, setCentersState] = useState<CentersState>(defaultCentersState);

  const getCenters = useCallback(
    async ({ pageIndex, pageSize }: PaginationOptions) => {
      const showErrorNotification = (message: string) => {
        setCentersState(defaultCentersState);
        showNotification(false, message, true);
      };

      setCentersState((state) => ({ ...state, loadingCentersList: true }));

      try {
        const response = await apiService.get<PaginatedResult<Center[]>>(url, { page: pageIndex, perPage: pageSize });
        setCentersState((state) => ({
          centers: response.data.data,
          total: response.data.total,
          loadingCentersList: false
        }));
      } catch (error) {
        setCentersState((state) => ({ ...state, loadingCentersList: false }));
        showErrorNotification((error.response?.data?.message as string) || 'Failed to retrieve Centers');
      }
    },
    [url]
  );

  return [
    {
      centersState,
      setCentersState
    },
    { getCenters }
  ];
};
