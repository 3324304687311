import { useCallback, useState } from 'react';
import { ProductPurchaseInterface } from '@efacity/common';
import { apiService, Organization } from '@efacity/frontend-shared';
import { FetchDataOptions, initialFetchDataOptions } from '../../components/Table/Table';
import { cloneDeep } from 'lodash';
import removeDateOffsetFromFilters from '../../utils/removeDateOffsetFromFilters';
import { getFiltersQueryParameters, mapReactTableSortToApiSort } from '../../utils/queryHelpers';

export interface UserProductPurchasesExtended extends ProductPurchaseInterface {
  organization: Partial<Organization>;
}
export interface UserProductPurchasesState {
  productPurchases: UserProductPurchasesExtended[];
  total: number;
  isLoading: boolean;
  fetchDataOptions: FetchDataOptions;
}
export const emptyUserProductPurchasesState = {
  productPurchases: [],
  total: 0,
  isLoading: false,
  fetchDataOptions: initialFetchDataOptions
};

export const useCustomerProductPurchases = (userId) => {
  const [userProductPurchasesState, setUserProductPurchasesState] =
    useState<UserProductPurchasesState>(emptyUserProductPurchasesState);

  const getCustomerProductPurchases = useCallback(
    async (fetchDataOptions: FetchDataOptions): Promise<void> => {
      setUserProductPurchasesState((prevState) => ({
        ...prevState,
        isLoading: true,
        fetchDataOptions: { ...fetchDataOptions }
      }));
      const clonedFetchDataOptions = cloneDeep(fetchDataOptions);
      const { filters, pageIndex, pageSize, sortBy } = clonedFetchDataOptions;
      const dateFilters = ['updatedAt'];
      removeDateOffsetFromFilters(filters, dateFilters);

      try {
        const { data } = await apiService.get<{ data: UserProductPurchasesExtended[]; total: number }>(
          `/users/${userId}/product-purchases`,
          {
            page: pageIndex,
            perPage: pageSize,
            ...getFiltersQueryParameters(filters),
            sortBy: mapReactTableSortToApiSort(sortBy)
          }
        );
        setUserProductPurchasesState((prevState) => ({
          ...prevState,
          productPurchases: data.data,
          total: data.total,
          isLoading: false
        }));
      } catch {
        setUserProductPurchasesState(emptyUserProductPurchasesState);
      }
    },
    [userId]
  );

  return { getCustomerProductPurchases, userProductPurchasesState };
};
