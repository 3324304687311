import { Messages, SendStatuses } from '@efacity/common';
import { useCallback, useState } from 'react';
import { FetchDataOptions, initialFetchDataOptions } from '../../components/Table/Table';
import { apiService, Message } from '@efacity/frontend-shared';
import { showNotification } from '@efacity/frontend-next-shared/notifications';
import { getFiltersQueryParameters, mapReactTableSortToApiSort } from '../../utils/queryHelpers';
import removeDateOffsetFromFilters from '../../utils/removeDateOffsetFromFilters';

export interface MessagesState {
  messages: Message[];
  total: number;
  timeZone: string;
  isLoading: boolean;
  fetchDataOptions: FetchDataOptions;
  processingMessageId: string | null;
}
export const defaultMessagesState: MessagesState = {
  messages: [],
  total: 0,
  timeZone: '',
  isLoading: false,
  fetchDataOptions: initialFetchDataOptions,
  processingMessageId: null
};
export const useMessages = (orgId: string) => {
  const [messagesState, setMessagesState] = useState<MessagesState>(defaultMessagesState);

  const getMessages = useCallback(
    async (fetchDataOptions: FetchDataOptions): Promise<void> => {
      setMessagesState((prevState) => ({
        ...prevState,
        isLoading: true,
        fetchDataOptions: { ...fetchDataOptions }
      }));
      const { pageIndex, pageSize, filters, sortBy } = fetchDataOptions;
      const dateFilters = ['createdOn', 'sentOn'];
      removeDateOffsetFromFilters(filters, dateFilters);

      try {
        const { data } = await apiService.get<{ data: Message[]; total: number; timeZone: string }>(
          `/org/${orgId}/messages`,
          {
            page: pageIndex,
            perPage: pageSize,
            ...getFiltersQueryParameters(filters),
            sortBy: mapReactTableSortToApiSort(sortBy)
          }
        );

        setMessagesState((prevState) => ({
          ...prevState,
          messages: data.data,
          total: data.total,
          timeZone: data.timeZone,
          isLoading: false
        }));
      } catch (error) {
        setMessagesState((state) => ({ ...state, isLoading: false }));
        showNotification(false, error.response?.data?.message || Messages.FailedGetMessages);
      }
    },
    [orgId]
  );

  const resendMessage = async (messageId: string): Promise<void> => {
    setMessagesState((prevState) => ({ ...prevState, processingMessageId: messageId }));

    try {
      const { data } = await apiService.post<{ status: SendStatuses }>(`/org/${orgId}/message/${messageId}/resend`, {});

      setMessagesState((prevState) => {
        const updatedMessages = prevState.messages.map((message) => {
          if (message._id === messageId) {
            message.status = data.status;
          }
          return message;
        });
        return { ...prevState, messages: updatedMessages, processingMessageId: null };
      });
    } catch (error) {
      showNotification(false, error.response?.data?.message || Messages.FailedResendMessage);
      setMessagesState((prevState) => ({ ...prevState, processingMessageId: null }));
    }
  };

  return { messagesState, setMessagesState, getMessages, resendMessage };
};
