import { PageTitle } from '@efacity/react-next-sc';
import Table, { EnhancedColumn, PaginationTypes } from '../../components/Table/Table';
import {
  ApplicationStatus,
  ApplicationStatusConnector,
  convertUtcToZonedTime,
  EnrollmentApplicationCommon,
  joinNames,
  Messages,
  moneyWithCurrencyCodeFormatter,
  nowUTC,
  formatStringToMonthDayYearDate
} from '@efacity/common';
import { useCallback, useEffect, useState } from 'react';
import { adminSectionTableFontSize, apiService, ConfirmationModal } from '@efacity/frontend-shared';
import { showNotification } from '@efacity/frontend-next-shared/notifications';
import { tableStyles } from '../../stylesheets/tableStyle';
import { NavLink } from 'react-router-dom';
import { PATHS, toPath } from '@efacity/routing';
import { IconButton, Link } from '@mui/material';
import { smallPublishButtonStyle } from '../../components/Buttons/SessionPublishButton';
import { isBefore } from 'date-fns';
import DeleteIcon from '@mui/icons-material/DeleteForever';
import url from 'url';

const headerSx = { ...tableStyles.tableHeaderCellSx, ...tableStyles.tableHead };

type EnrollmentApplicationWithOrgName = EnrollmentApplicationCommon & { orgName: string };

const UserEnrollmentApplications = () => {
  const fetchDataUrl = `enrollments`;

  const [rowsData, setRowsData] = useState({
    data: new Array<EnrollmentApplicationWithOrgName>(),
    isLoading: false
  });

  const defaultConfirmationModalState = {
    isOpen: false,
    isOperating: false,
    message: '',
    onConfirm: null
  };
  const [confirmationModalState, setConfirmationModalState] = useState(defaultConfirmationModalState);

  const getEnrollmentApplications = useCallback(async () => {
    const showErrorNotification = (message: string) => {
      setRowsData(() => ({ data: [], isLoading: false }));
      showNotification(false, message, true);
    };

    try {
      const response = await apiService.get<{ data: EnrollmentApplicationWithOrgName[] }>(fetchDataUrl);
      setRowsData(() => ({ data: response.data.data, isLoading: false }));
    } catch (error) {
      showErrorNotification((error.response?.data?.message as string) || Messages.FailedGetEnrollmentApplications);
    }
  }, [fetchDataUrl]);

  useEffect(() => {
    getEnrollmentApplications();
  }, [getEnrollmentApplications]);

  const onDeleteEnrollmentCLick = (enrollmentId, name: string) => {
    setConfirmationModalState({
      isOpen: true,
      isOperating: false,
      message:
        `You are about to delete Enrollment Application for ${name}.<br/>` +
        '<div style="display:flex;justify-content:center">Please, confirm.</div>',
      onConfirm: () => handleDeleteEnrollmentApplication(enrollmentId)
    });
  };
  const handleDeleteEnrollmentApplication = async (enrollmentId: string) => {
    setConfirmationModalState((state) => {
      return { ...state, isOperating: true };
    });
    try {
      await apiService.delete(`enrollments/enrollment/${enrollmentId}`);
    } catch (error) {
      showNotification(false, (error.response?.data?.message as string) || Messages.FailedDeleteEnrollment, true);
    } finally {
      setConfirmationModalState({ ...defaultConfirmationModalState });
    }
    await getEnrollmentApplications();
  };

  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const columns: EnhancedColumn<EnrollmentApplicationWithOrgName>[] = [
    { Header: 'School Name', accessor: 'orgName', disableFilters: true, disableSortBy: true, headerSx },
    {
      Header: 'Student Name',
      id: 'enrollmentForm.student.firstName',
      accessor: (data) => {
        return data.enrollmentForm.student.firstName;
      },
      Cell: ({ row }) => {
        const studentName =
          row.original.enrollmentForm.student?.firstName?.length > 0 ||
          row.original.enrollmentForm.student?.lastName?.length > 0
            ? joinNames(row.original.enrollmentForm.student.firstName, row.original.enrollmentForm.student.lastName)
            : '[no name]';

        const enrollmentUrl = url.format({
          pathname: toPath(PATHS.enrollment, { orgId: row.original.orgId }),
          query: { enrollmentId: row.original._id }
        });
        return (
          <Link component={NavLink} to={enrollmentUrl}>
            {studentName}
          </Link>
        );
      },
      disableFilters: true,
      disableSortBy: true,
      headerSx
    },
    {
      Header: 'Submitted On',
      accessor: 'submittedOn',
      disableFilters: true,
      Cell: ({ value }) =>
        value ? formatStringToMonthDayYearDate(convertUtcToZonedTime(value, timeZone).toISOString()) : '-',
      headerSx
    },
    {
      Header: 'Approved On',
      accessor: 'approvedAt',
      disableFilters: true,
      Cell: ({ value }) =>
        value ? formatStringToMonthDayYearDate(convertUtcToZonedTime(value, timeZone).toISOString()) : '-',
      headerSx
    },
    {
      Header: 'Price',
      accessor: 'price',
      columnStyles: { textAlign: 'right', width: 115, maxWidth: 115 },
      headerStyles: { textAlign: 'right', paddingRight: 15 },
      Cell: ({ value, row }) => moneyWithCurrencyCodeFormatter(value, row.original.currencyCode),
      disableFilters: true,
      disableSortBy: true,
      headerSx
    },
    {
      Header: 'Status',
      accessor: 'applicationStatus',
      columnStyles: { width: 160, maxWidth: 160 },
      Cell: ({ value }) => ApplicationStatusConnector[value],
      disableFilters: true,
      disableSortBy: true,
      headerSx
    },
    {
      Header: '',
      id: 'actions-cell',
      disableSortBy: true,
      columnStyles: { ...adminSectionTableFontSize, width: 75, maxWidth: 75 },
      Cell: ({ row }) =>
        row.original.applicationStatus !== ApplicationStatus.Approved ? (
          <IconButton
            color="primary"
            aria-label="delete application"
            component="span"
            style={smallPublishButtonStyle}
            disabled={isBefore(new Date(row.original.expiresOn), new Date(nowUTC()))}
            onClick={() =>
              onDeleteEnrollmentCLick(
                row.original._id,
                joinNames(
                  row.original.enrollmentForm.student.firstName,
                  row.original.enrollmentForm.student.lastName
                ) || '[no name]'
              )
            }
          >
            <DeleteIcon />
          </IconButton>
        ) : (
          <> </>
        ),
      headerSx
    }
  ];

  return (
    <div>
      <PageTitle title="Enrollment applications" />
      <Table
        columns={columns}
        data={rowsData.data}
        loading={rowsData.isLoading}
        paginationType={PaginationTypes.ClientSide}
        noDataText="You have no enrollment applications"
      />
      {confirmationModalState.isOpen && (
        <ConfirmationModal
          open={confirmationModalState.isOpen}
          isOperating={confirmationModalState.isOperating}
          message={confirmationModalState.message}
          onClose={() => setConfirmationModalState({ ...defaultConfirmationModalState })}
          onConfirm={() => confirmationModalState.onConfirm(confirmationModalState)}
        />
      )}
    </div>
  );
};

export default UserEnrollmentApplications;
