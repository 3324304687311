import { apiService, getMergedFormValues } from '@efacity/frontend-shared';
import { useCallback, useEffect, useState } from 'react';
import { AdditionalSessionOption, CurrencyCode, FormMode } from '@efacity/common';
import { useOrganizationRegionsOptions } from './useOrganizationRegionsOptions';
import { addMonths } from 'date-fns';
import { showNotification } from '@efacity/frontend-next-shared/notifications';

export interface AdditionalSessionOptionState {
  data: AdditionalSessionOption[];
  isLoading: boolean;
}

export const additionalSessionOptionInitialFormValue = {
  name: '',
  description: '',
  price: 0,
  currencyCode: CurrencyCode.USD,
  createdAt: '',
  expiresOn: addMonths(new Date(), 1)
};

export const useAdditionalSessionOptionsLoader = <T>(url: string, params?: T) => {
  const [additionalSessionOptionsState, setAdditionalSessionOptionsState] = useState<AdditionalSessionOptionState>({
    data: [],
    isLoading: true
  });

  const getAdditionalSessionOptions = useCallback(() => {
    const setAdditionalSessionOptions = (additionalSessionOptions: AdditionalSessionOption[], isLoading: boolean) => {
      setAdditionalSessionOptionsState((additionalSessionOptionsState) => ({
        ...additionalSessionOptionsState,
        data: additionalSessionOptions,
        isLoading: isLoading
      }));
    };

    const showErrorNotification = (message: string) => {
      setAdditionalSessionOptions([], false);
      showNotification(false, message, true);
    };

    apiService.get<AdditionalSessionOption[]>(url, params).then(
      (result) => {
        return setAdditionalSessionOptions(result.data, false);
      },
      (error) => {
        setAdditionalSessionOptionsState((additionalSessionOptionsState) => ({
          ...additionalSessionOptionsState,
          isLoading: false
        }));
        showErrorNotification(
          (error.response?.data?.message as string) || 'Failed to retrieve Additional Session Options from ' + url
        );
      }
    );
  }, [params, url]);

  useEffect(() => {
    getAdditionalSessionOptions();
  }, [getAdditionalSessionOptions]);

  return {
    additionalSessionOptionsState: additionalSessionOptionsState,
    setAdditionalSessionOptionsState: setAdditionalSessionOptionsState,
    getAdditionalSessionOptions: getAdditionalSessionOptions
  };
};

export const useAdditionalSessionOptionLoader = (
  orgId: string,
  additionalSessionOptionId: string,
  formMode: FormMode
) => {
  const {
    organizationRegions: { defaultCurrency: currency, isLoading: isCurrencyLoading }
  } = useOrganizationRegionsOptions(orgId);

  const [additionalSessionOptionFormState, setAdditionalSessionFormState] = useState({
    additionalSessionOptionFormValue: additionalSessionOptionInitialFormValue,
    isLoading: true
  });

  useEffect(() => {
    const getAdditionalSessionOptionById = async (orgId: string) => {
      setAdditionalSessionFormState({
        additionalSessionOptionFormValue: { ...additionalSessionOptionInitialFormValue, currencyCode: currency },
        isLoading: true
      });

      try {
        const { data } = await apiService.get<AdditionalSessionOption>(
          `/org/${orgId}/additionalOption/${additionalSessionOptionId}`
        );
        const additionalSessionOption = { ...data };
        delete additionalSessionOption._id;
        delete additionalSessionOption.createdAt;
        delete additionalSessionOption.owner;

        const mergeStateValues = (additionalSessionOptionFormState) => ({
          ...additionalSessionOptionFormState,
          additionalSessionOptionFormValue: {
            ...getMergedFormValues(
              {
                ...additionalSessionOptionInitialFormValue,
                currencyCode: currency
              },
              additionalSessionOption
            ),
            expiresOn: new Date(additionalSessionOption.expiresOn)
          },
          isLoading: isCurrencyLoading
        });

        setAdditionalSessionFormState(mergeStateValues);
      } catch (error) {
        showNotification(false, error.message || 'Failed to get Additional Option info.', true);
        setAdditionalSessionFormState({
          additionalSessionOptionFormValue: { ...additionalSessionOptionInitialFormValue, currencyCode: currency },
          isLoading: true
        });
      }
    };
    if (formMode === FormMode.Add) {
      setAdditionalSessionFormState((additionalSessionOptionFormState) => ({
        ...additionalSessionOptionFormState,
        additionalSessionOptionFormValue: {
          ...additionalSessionOptionInitialFormValue,
          currencyCode: currency
        },
        isLoading: isCurrencyLoading
      }));
    } else {
      getAdditionalSessionOptionById(orgId);
    }
  }, [orgId, additionalSessionOptionId, formMode, isCurrencyLoading, currency]);

  return {
    additionalSessionOptionFormState
  };
};
