import React, { useEffect, useState } from 'react';
import { Dialog, DialogContent, DialogProps } from '@mui/material';
import { apiService, PaymentPlatformSmallInfo, FormGroupDivider, useFetch } from '@efacity/frontend-shared';
import { CancelSubmitButtons } from '../../../components/CancelSubmitButtond';
import PaymentPlatformSelector from '../PaymentPlatformSelector/PaymentPlatformSelector';
import { Messages } from '@efacity/common';
import { ModalDialogTitle } from '@efacity/react-next-sc';
import { showNotification } from '@efacity/frontend-next-shared/notifications';

export interface PaymentPlatformModalProps extends DialogProps {
  orgId: string;
  schoolName: string;
  organizationStripeAccountId: string;
  onClose: () => void;
}

const SelectPaymentPlatformModal: React.FC<PaymentPlatformModalProps> = ({
  orgId,
  open,
  schoolName,
  organizationStripeAccountId,
  onClose
}) => {
  const [selectedAccountId, setSelectedAccountId] = useState(organizationStripeAccountId);
  const [isUpdating, setIsUpdating] = useState(false);
  const [{ data: availablePaymentAccounts, isLoading }] = useFetch<PaymentPlatformSmallInfo[]>(
    '/paymentPlatforms/active',
    {
      initialDataState: [],
      initialLoadingState: true
    }
  );
  useEffect(() => {
    setSelectedAccountId(organizationStripeAccountId);
  }, [organizationStripeAccountId, setSelectedAccountId]);

  const handleSubmit = async (newlySelectedStripeAccountId: string) => {
    try {
      setIsUpdating(true);
      await apiService.post(`/org/${orgId}/stripeAccount`, {
        accountId: newlySelectedStripeAccountId
      });
      setIsUpdating(false);
      onClose();
      showNotification(true, Messages.OrgPaymentAccountUpdated);
    } catch (errorResponse) {
      setIsUpdating(false);
      showNotification(false, errorResponse?.response?.data?.message || Messages.FailedUpdateOrgStripeAccount, true);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <ModalDialogTitle text={`Select Stripe Account for ${schoolName}`} onClose={onClose} />
      <DialogContent>
        <PaymentPlatformSelector
          selectedPlatformId={selectedAccountId || ''}
          paymentPlatforms={availablePaymentAccounts}
          onSelect={setSelectedAccountId}
        />
        <FormGroupDivider marginY={0} />
        <CancelSubmitButtons
          onCancel={onClose}
          onSubmit={() => handleSubmit(selectedAccountId)}
          disabled={isLoading || isUpdating}
        />
      </DialogContent>
    </Dialog>
  );
};

export default SelectPaymentPlatformModal;
