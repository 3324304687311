import { Messages, FormMode, Media, parseRichText } from '@efacity/common';
import { apiService, getMergedFormValues } from '@efacity/frontend-shared';
import { showNotification } from '@efacity/frontend-next-shared/notifications';
import { useEffect, useState } from 'react';
import { KitBackendValues, kitInitialFormValues, ProductFormValues } from './productFormValues';
import { useOrganizationRegionsOptions } from '../../hooks/useOrganizationRegionsOptions';
import { cloneDeep } from 'lodash';

const productDefaultFormState = {
  kitFormValues: { ...kitInitialFormValues },
  isLoading: true
};
export interface ProductWithRegionsFormState {
  productFormState: ProductFormValues;
  isLoading: boolean;
  isPaymentAllowed: boolean;
}
const productWithRegionsDefaultFormState = {
  kitFormValues: { ...kitInitialFormValues },
  isLoading: true,
  isPaymentAllowed: false
};

export const useProductsLoader = (orgId: string, productId: string, formMode: FormMode) => {
  const {
    organizationRegions: { defaultCurrency: currency, isLoading: isCurrencyLoading, isPaymentsAllowed }
  } = useOrganizationRegionsOptions(orgId);

  const [productFormState, setProductFormState] = useState(productDefaultFormState);
  const [productWithRegionsFormState, setProductWithRegionsFormState] = useState(productWithRegionsDefaultFormState);

  useEffect(() => {
    const getKitById = async (orgId: string) => {
      setProductFormState({
        kitFormValues: cloneDeep(kitInitialFormValues),
        isLoading: true
      });

      try {
        const { data } = await apiService.get<KitBackendValues>(`/org/${orgId}/product/${productId}`);
        const kit = { ...data };

        const media: Media[] = kit?.media?.map((mediaUrl: string) => ({
          link: mediaUrl
        }));

        const description = parseRichText(kit.description);
        setProductFormState((kitFormState) => ({
          ...kitFormState,
          kitFormValues: cloneDeep({
            ...getMergedFormValues({ ...kitInitialFormValues }, kit),
            media: media,
            description
          }),
          isLoading: false
        }));
      } catch (error) {
        showNotification(false, error.message || Messages.FailedGetKits, true);
        setProductFormState({
          kitFormValues: cloneDeep({ ...kitInitialFormValues }),
          isLoading: false
        });
      }
    };

    if (formMode === FormMode.Add) {
      setProductFormState({
        kitFormValues: cloneDeep(kitInitialFormValues),
        isLoading: false
      });
    } else {
      getKitById(orgId);
    }
  }, [orgId, productId, formMode]);

  useEffect(() => {
    if (!isCurrencyLoading && !productFormState.isLoading) {
      const newValues = isPaymentsAllowed
        ? { ...productFormState.kitFormValues, currencyCode: currency }
        : { ...productFormState.kitFormValues, currencyCode: currency, price: 0 };
      setProductWithRegionsFormState({
        kitFormValues: cloneDeep(newValues),
        isPaymentAllowed: isPaymentsAllowed,
        isLoading: false
      });
    } else {
      setProductWithRegionsFormState(productWithRegionsDefaultFormState);
    }
  }, [isCurrencyLoading, productFormState.isLoading, currency, isPaymentsAllowed, productFormState.kitFormValues]);

  return { productWithRegionsFormState };
};
