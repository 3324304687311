import { apiService } from '@efacity/frontend-shared';
import { useCallback, useState } from 'react';
import { showNotification } from '@efacity/frontend-next-shared/notifications';
import { PaymentPlatform } from '@efacity/common';

export interface PaymentPlatformsState {
  paymentPlatforms: PaymentPlatform[];
  isLoading: boolean;
}

export const usePaymentPlatformsLoader = (url: string) => {
  const [paymentPlatformsState, setPaymentPlatformsState] = useState<PaymentPlatformsState>({
    paymentPlatforms: [],
    isLoading: true
  });

  const getPaymentPlatforms = useCallback(() => {
    const setStripeAccounts = (data: PaymentPlatform[], isLoading: boolean) => {
      setPaymentPlatformsState({
        paymentPlatforms: data,
        isLoading: isLoading
      });
    };

    const showErrorNotification = (message: string) => {
      setStripeAccounts([], false);
      showNotification(false, message, true);
    };

    apiService.get<PaymentPlatform[]>(url).then(
      (result) => {
        return setStripeAccounts(result.data, false);
      },
      (error) => {
        setPaymentPlatformsState((stripeAccountsState) => ({
          paymentPlatforms: stripeAccountsState.paymentPlatforms,
          isLoading: false
        }));
        showErrorNotification(
          (error.response?.data?.message as string) || 'Failed to get Payment Platforms from ' + url
        );
      }
    );
  }, [url]);

  return [
    {
      paymentPlatformsState: paymentPlatformsState,
      setPaymentPlatformsState: setPaymentPlatformsState
    },
    { getPaymentPlatforms: getPaymentPlatforms }
  ];
};
