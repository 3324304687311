import { apiService } from '@efacity/frontend-shared';
import { useCallback, useState } from 'react';
import { showNotification } from '@efacity/frontend-next-shared/notifications';
import { Messages, PaginatedResult, Tag } from '@efacity/common';
import { PaginationOptions } from '../components/Table/Table';

export interface TagsState {
  tags: Tag[];
  isLoading: boolean;
  total: number;
}

export const useTagsLoader = (url: string) => {
  const [tagsState, setTagsState] = useState<TagsState>({
    tags: [],
    total: 0,
    isLoading: true
  });

  const getTags = useCallback(
    ({ pageIndex, pageSize }: PaginationOptions) => {
      const setDiscounts = ({ data, total }: PaginatedResult<Tag[]>, isLoading: boolean) => {
        setTagsState({
          tags: data,
          total,
          isLoading: isLoading
        });
      };

      const showErrorNotification = (message: string) => {
        setDiscounts({ data: [], total: 0 }, false);
        showNotification(false, message, true);
      };

      apiService.get<PaginatedResult<Tag[]>>(url, { page: pageIndex, perPage: pageSize }).then(
        (result) => {
          return setDiscounts(result.data, false);
        },
        (error) => {
          setTagsState((tagsState) => ({
            ...tagsState,
            isLoading: false
          }));
          showErrorNotification((error.response?.data?.message as string) || Messages.FailedGetTags);
        }
      );
    },
    [url]
  );

  return [
    {
      tagsState,
      setTagsState
    },
    { getTags }
  ];
};
