import { apiService } from '@efacity/frontend-shared';
import { useCallback, useState } from 'react';
import { showNotification } from '@efacity/frontend-next-shared/notifications';
import { Credit, Messages, User } from '@efacity/common';
import { FetchDataOptions } from '../../components/Table/Table';
import { getFiltersQueryParameters, mapReactTableSortToApiSort } from '../../utils/queryHelpers';

export interface OrganizationCreditsResponse {
  data: Credit[];
  total?: number;
}

export interface OrganizationCreditsState {
  list: User[];
  isLoading: boolean;
  total: number;
}
export const defaultOrganizationCreditsState: OrganizationCreditsState = {
  list: [],
  total: 0,
  isLoading: false
};

export const useOrganizationCreditsLoader = (url: string) => {
  const [organizationCreditsState, setOrganizationCreditsState] = useState<OrganizationCreditsState>({
    list: [],
    total: 0,
    isLoading: true
  });

  const getOrganizationCredits = useCallback(
    async ({ pageIndex, pageSize, filters, sortBy }: FetchDataOptions) => {
      const showErrorNotification = (message: string) => {
        setOrganizationCreditsState(defaultOrganizationCreditsState);
        showNotification(false, message, true);
      };

      try {
        const response = await apiService.get<OrganizationCreditsResponse>(url, {
          page: pageIndex,
          perPage: pageSize,
          ...getFiltersQueryParameters(filters),
          sortBy: mapReactTableSortToApiSort(sortBy)
        });

        setOrganizationCreditsState({ list: response.data.data, total: response.data.total, isLoading: false });
      } catch (error) {
        setOrganizationCreditsState(defaultOrganizationCreditsState);
        showErrorNotification((error.response?.data?.message as string) || Messages.FailedOrganizationCredits);
      }
    },
    [url]
  );
  return { organizationCreditsState, getOrganizationCredits };
};
