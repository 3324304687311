import { Box, Button, Dialog, DialogContent } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import SharedWithOrganizationsForm from './SharedWithOrganizationsForm';
import { apiService, CenterIdName } from '@efacity/frontend-shared';
import { Messages, OwnerType, SharedWith } from '@efacity/common';
import CircularProgress from '@mui/material/CircularProgress';
import ShareIcon from '@mui/icons-material/Share';
import { FormProvider, useForm } from 'react-hook-form';
import { ModalDialogTitle } from '@efacity/react-next-sc';
import { showNotification } from '@efacity/frontend-next-shared/notifications';

const buttonSx = {
  width: { xs: '100%', sm: '150px' },
  height: '32px',
  textTransform: 'uppercase',
  fontSize: 14
};

const buttonsContainerSx = {
  display: 'flex',
  flexDirection: { xs: 'column', sm: 'row' },
  justifyContent: 'center',
  gap: 2,
  m: 2
};

export interface SharedWithOrganizationsModalProps {
  isOpen: boolean;
  orgId: string;
  activityId: string;
  activityName: string;
  ownerType: OwnerType;
  sharing: {
    sharedWith: string[];
    shareWithAllOrganizations: boolean;
    certificateRequired: boolean;
  };
  handleClose: () => void;
  handleCloseAndReload: () => void;
}

const SharedWithOrganizationsModal: FC<SharedWithOrganizationsModalProps> = ({
  isOpen,
  orgId,
  activityId,
  activityName,
  ownerType,
  sharing,
  handleClose,
  handleCloseAndReload
}) => {
  const [organizationsState, setOrganizationsState] = useState({
    isLoading: false,
    data: [],
    error: null
  });

  const methods = useForm<SharedWith>({
    defaultValues: sharing
  });
  const { isValid, isDirty, isSubmitting } = methods.formState;

  useEffect(() => {
    const getCentersBySchoolActivityCreator = async (orgId: string) => {
      try {
        setOrganizationsState((sharedWithOrganizationState) => ({
          ...sharedWithOrganizationState,
          isLoading: true
        }));
        const { data } = await apiService.get<CenterIdName[]>(`schools/${orgId}/centersNames`);
        setOrganizationsState((sharedWithOrganizationState) => ({
          ...sharedWithOrganizationState,
          data,
          isLoading: false
        }));
      } catch (error) {
        showNotification(false, error.message as string, true);
        setOrganizationsState((sharedWithOrganizationState) => ({
          ...sharedWithOrganizationState,
          isLoading: false
        }));
      }
    };

    if (ownerType === OwnerType.School) {
      getCentersBySchoolActivityCreator(orgId);
    }
  }, [orgId, ownerType]);

  const onSubmit = async (formValues) => {
    try {
      await apiService.patch<string>(`/org/${orgId}/activity/${activityId}/share`, formValues);
      showNotification(true, Messages.ActivityShared, false);
      handleCloseAndReload();
    } catch (errorResponse) {
      showNotification(false, errorResponse.message || Messages.FailedShareActivity, true);
    }
  };

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <ModalDialogTitle text={activityName} onClose={handleClose} />
      <DialogContent>
        <FormProvider {...methods}>
          <form noValidate onSubmit={methods.handleSubmit(onSubmit)}>
            <SharedWithOrganizationsForm
              ownerType={ownerType}
              centers={organizationsState.data}
              disabled={isSubmitting}
            />
            <Box sx={buttonsContainerSx}>
              <Button
                id="dontMakePublicBtn"
                sx={{ ...buttonSx, border: 1 }}
                color="primary"
                variant="outlined"
                onClick={handleClose}
                disabled={isSubmitting}
                data-testid="cancel-share-activity-btn"
              >
                Cancel
              </Button>
              <Button
                sx={buttonSx}
                color="primary"
                variant="contained"
                type="submit"
                disabled={!(isValid && isDirty) || isSubmitting}
                data-testid="share-activity-btn"
              >
                {isSubmitting ? (
                  <CircularProgress
                    variant="indeterminate"
                    data-testid="loader"
                    size={20}
                    style={{ marginRight: 20, color: 'white' }}
                  />
                ) : (
                  <ShareIcon style={{ marginRight: 20 }} />
                )}
                Share
              </Button>
            </Box>
          </form>
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
};

export default SharedWithOrganizationsModal;
