import { Activity, convertUtcToZonedTime, Roles, formatDateToMonthDayYearInLocale } from '@efacity/common';
import { showNotification } from '@efacity/frontend-next-shared/notifications';
import { apiService, ConfirmationModal } from '@efacity/frontend-shared';
import { hasFrontendAccessWithRoleForOrganization, PageTitle, useAuth } from '@efacity/react-next-sc';
import { PATHS, toPath } from '@efacity/routing';
import { Box, Link } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import ActivityRegistrationLinkWithCopier from '../../components/ActivityRegistrationLinkWithCopier/ActivityRegistrationLinkWithCopier';
import Table, {
  EnhancedColumn,
  FetchDataOptions,
  initialFetchDataOptions,
  PaginationTypes
} from '../../components/Table/Table';
import { OrgIdParamType } from '@efacity/frontend-next-shared/utils';
import ActivitiesTableActionsCell from '../ActivitiesContainer/ActivitiesTableActionsCell';
import SharedWithOrganizationsModal from '../ActivitiesContainer/ActivityForm/SharedWithOrganizationsModal';
import { useActivitiesLoader } from '../ActivitiesContainer/useActivitiesLoader';

export const CoursesContainer: React.FC = () => {
  const {
    authState: { user }
  } = useAuth();
  const { orgId } = useParams<OrgIdParamType>();

  const {
    activitiesState: { activities, total, isLoading, timeZone },
    getActivities
  } = useActivitiesLoader(`/org/${orgId}/activities/courses`);

  const allowedShareActivity = hasFrontendAccessWithRoleForOrganization(
    orgId,
    [Roles.SchoolAdmin, Roles.IndependentTeacher],
    user.adminAccesses || []
  );

  const navigate = useNavigate();

  const sharedModalDefaultState = {
    activityId: null,
    activityName: '',
    ownerType: null,
    orgId: null,
    sharing: {
      sharedWith: [],
      shareWithAllOrganizations: false,
      certificateRequired: false
    },
    fetchDataOptions: initialFetchDataOptions
  };
  const [sharedModalState, setSharedModalState] = useState(sharedModalDefaultState);

  const handleStartShareActivity = (activity: Activity, fetchDataOptions: FetchDataOptions) => {
    setSharedModalState({
      activityId: activity._id,
      activityName: activity.name,
      ownerType: activity.owner.ownerType,
      orgId: orgId,
      sharing: {
        sharedWith: activity.sharedWith,
        shareWithAllOrganizations: activity.shareWithAllOrganizations,
        certificateRequired: activity.certificateRequired
      },
      fetchDataOptions: fetchDataOptions
    });
  };
  const handleEndShareActivity = (fetchDataOptions: FetchDataOptions) => {
    getActivities(fetchDataOptions);
    setSharedModalState(sharedModalDefaultState);
  };

  const defaultConfirmationModalState = {
    isOpen: false,
    isOperating: false,
    message: '',
    name: '',
    activityId: null,
    onConfirm: null,
    fetchDataOptions: initialFetchDataOptions
  };
  const [confirmationModalState, setConfirmationModalState] = useState(defaultConfirmationModalState);
  const handleDeleteActivityClick = (activityId: string, name: string, fetchDataOptions: FetchDataOptions) => {
    setConfirmationModalState((state) => {
      return {
        ...state,
        isOpen: true,
        isOperating: false,
        message:
          '<div style="display:flex;justify-content:center">You are about to delete</div>' +
          `<div style="display:flex;justify-content:center">"${name}".</div>`,
        activityId: activityId,
        fetchDataOptions: fetchDataOptions,
        onConfirm: handleDeleteActivity
      };
    });
  };

  const handleDeleteActivity = (currentConfirmationModalState) => {
    apiService.delete(`/org/${orgId}/activity/${currentConfirmationModalState.activityId}`).then(
      (result: any) => {
        getActivities(currentConfirmationModalState.fetchDataOptions);
        setConfirmationModalState({ ...defaultConfirmationModalState });
        showNotification(true, result.data.message as string, false);
      },
      (error) => {
        setConfirmationModalState({ ...currentConfirmationModalState, isOperating: false });
        showNotification(false, error.response.data.message as string, true);
      }
    );
  };

  const handleCloneActivity = (activityId: string) => {
    navigate(toPath(PATHS.addActivity, { orgId: orgId }), { state: { isCloning: true, activityId: activityId } });
  };

  const columns = useMemo<EnhancedColumn<Activity>[]>(
    () => {
      const columns: EnhancedColumn<Activity>[] = [
        {
          Header: 'Name',
          accessor: 'name',
          Cell: ({ row }) => (
            <Box display="flex" alignItems="center" data-testid="activity-name-copier-cell">
              <Link
                component={NavLink}
                to={toPath(PATHS.editActivity, {
                  orgId: orgId,
                  id: row.original._id
                })}
                underline={'none'}
              >
                {row.original.name}
              </Link>
              &nbsp;&nbsp;
              <ActivityRegistrationLinkWithCopier
                isEnabled={true}
                activityId={row.original._id}
                orgId={orgId}
                activityType={row.original.type}
              />
            </Box>
          )
        },
        {
          Header: 'Created on',
          accessor: 'createdAt',
          Cell: ({ value: createdAt }) => {
            return createdAt ? (
              <Box whiteSpace="nowrap">
                {formatDateToMonthDayYearInLocale(convertUtcToZonedTime(createdAt, timeZone))}
              </Box>
            ) : (
              <div></div>
            );
          },
          disableFilters: true,
          headerStyles: {
            whiteSpace: 'nowrap'
          }
        },
        {
          Header: '',
          id: 'actions-cell',
          disableFilters: true,
          columnStyles: { width: allowedShareActivity ? 170 : 75, maxWidth: allowedShareActivity ? 170 : 75 },
          Cell: ({ row, state }) => {
            return (
              <ActivitiesTableActionsCell
                activity={row.original}
                orgId={orgId}
                onShare={handleStartShareActivity}
                onClone={handleCloneActivity}
                onDelete={handleDeleteActivityClick}
                fetchDataOptions={state}
                allowedShareActivity={allowedShareActivity}
              />
            );
          }
        }
      ];

      return columns;
    },
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    [orgId, allowedShareActivity, handleCloneActivity, handleDeleteActivityClick]
  );

  const addButtonLink = orgId ? toPath(PATHS.addActivity, { orgId: orgId }) : PATHS.addActivity;

  return (
    <div>
      <PageTitle title="Courses" addButtonLink={addButtonLink} />
      <Table
        loadingSkeletonHeight={41}
        columns={columns}
        data={activities}
        loading={isLoading}
        fetchData={getActivities}
        pageCount={total}
        paginationType={PaginationTypes.ServerSide}
        initialSortBy={[{ id: 'name', desc: false }]}
      />
      {confirmationModalState.isOpen && (
        <ConfirmationModal
          open={confirmationModalState.isOpen}
          isOperating={confirmationModalState.isOperating}
          message={confirmationModalState.message}
          onClose={() => setConfirmationModalState({ ...defaultConfirmationModalState })}
          onConfirm={() => confirmationModalState.onConfirm(confirmationModalState)}
        />
      )}
      {sharedModalState.activityId && (
        <SharedWithOrganizationsModal
          isOpen={!!sharedModalState.activityId}
          orgId={sharedModalState.orgId}
          activityId={sharedModalState.activityId}
          activityName={sharedModalState.activityName}
          ownerType={sharedModalState.ownerType}
          sharing={sharedModalState.sharing}
          handleClose={() => setSharedModalState(sharedModalDefaultState)}
          handleCloseAndReload={() => handleEndShareActivity(sharedModalState.fetchDataOptions)}
        />
      )}
    </div>
  );
};
