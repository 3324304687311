import { Messages, formatStringToMonthDayYearDate } from '@efacity/common';
import { showNotification } from '@efacity/frontend-next-shared/notifications';
import { apiService, Certificate } from '@efacity/frontend-shared';
import { PageTitle, useAuth } from '@efacity/react-next-sc';
import { PATHS } from '@efacity/routing';
import { useCallback, useState } from 'react';
import Table, { EnhancedColumn, PaginationTypes } from '../../components/Table/Table';
import { tableStyles } from '../../stylesheets/tableStyle';

const headerSx = { ...tableStyles.tableHeaderCellSx, ...tableStyles.tableHead };

const UserCertificates = () => {
  const { authState } = useAuth();
  const userId = authState.user._id;
  const fetchDataUrl = `user/${userId}/certificates`;
  const certificationLandingLink = PATHS.instructorCertification;

  const [certificatesData, setCertificatesData] = useState({
    data: new Array<Certificate>(),
    isLoading: false
  });

  const getCertificates = useCallback(async () => {
    const showErrorNotification = (message: string) => {
      setCertificatesData((state) => ({ data: [], isLoading: false }));
      showNotification(false, message, true);
    };

    try {
      const response = await apiService.get<Certificate[]>(fetchDataUrl);

      setCertificatesData((state) => ({ data: response.data, isLoading: false }));
    } catch (error) {
      showErrorNotification((error.response?.data?.message as string) || Messages.FailedGetUserCertificates);
    }
  }, [fetchDataUrl]);

  const columns: EnhancedColumn<any>[] = [
    {
      Header: 'Activity Name',
      headerSx: headerSx,
      accessor: 'certificationForActivity.name',
      Cell: ({ row }) => {
        return row.original.certificationForActivity?.name || '[n/a]';
      },
      disableFilters: true,
      disableSortBy: true
    },
    {
      Header: 'Organization',
      headerSx: headerSx,
      accessor: 'organization.name',
      Cell: ({ row }) => {
        return row.original.organization?.name || '[n/a]';
      },
      disableFilters: true
    },
    {
      Header: 'Issued by',
      headerSx: headerSx,
      accessor: 'certificatedBy.fullName',
      Cell: ({ row }) => {
        return row.original.certificatedBy?.fullName || '[n/a]';
      },
      disableFilters: true
    },
    {
      Header: 'Expires on',
      headerSx: headerSx,
      accessor: 'expiresOn',
      disableFilters: true,
      Cell: ({ value: expiresOn }) => <>{formatStringToMonthDayYearDate(expiresOn)}</>
    }
  ];

  return (
    <div>
      <PageTitle title="Certificates" addButtonLink={certificationLandingLink} />
      <Table
        columns={columns}
        data={certificatesData.data}
        loading={certificatesData.isLoading}
        fetchData={getCertificates}
        pageCount={1}
        paginationType={PaginationTypes.ServerSide}
        noDataText="You have no certificates"
      />
    </div>
  );
};

export default UserCertificates;
