import {
  Box,
  IconButton,
  LinearProgress,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow
} from '@mui/material';

import React, { useEffect, useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import {
  adminSectionTableRowDefaultStyle,
  apiService,
  Center,
  ConfirmationModal,
  CircularProgressButton
} from '@efacity/frontend-shared';
import { PageTitle, PhoneNumber } from '@efacity/react-next-sc';
import { PATHS, toPath } from '@efacity/routing';
import { tableStyles } from '../../stylesheets/tableStyle';
import { IdOrgIdParamTypes } from '@efacity/frontend-next-shared/utils';
import { initialRowsPerPage } from '../../components/Table/Table';
import { smallPublishButtonStyle } from '../../components/Buttons/SessionPublishButton';
import { ConnectStatus, connectStatusViews } from '@efacity/common';
import StartConnectModal from '../../components/StartConnectModal/StartConnectModal';
import DeleteIcon from '@mui/icons-material/DeleteForever';
import { useCentersLoader } from './useCentersLoader';
import { showNotification } from '@efacity/frontend-next-shared/notifications';

const centersContainerSx = {
  ...tableStyles,
  hiddenSm: {
    display: {
      xs: 'none',
      sm: 'table-cell'
    }
  }
};

export const CentersContainer: React.FC = () => {
  const { orgId } = useParams<IdOrgIdParamTypes>();
  const [{ centersState }, { getCenters }] = useCentersLoader(`/org/${orgId}/schools/centers`);

  const defaultConnectModalState = {
    isOpen: false,
    isConnect: true,
    isOperating: false,
    id: null,
    name: '',
    message: '',
    connectName: ''
  };
  const [connectModalState, setConnectModalState] = useState(defaultConnectModalState);
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(initialRowsPerPage);

  useEffect(() => {
    getCenters({ pageIndex: page, pageSize: rowsPerPage });
  }, [getCenters, page, rowsPerPage]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const onConnectCLick = (centerId: string, name: string) => {
    setConnectModalState({
      isOpen: true,
      isConnect: true,
      isOperating: false,
      id: centerId,
      name: name,
      message: `You are about to Connect ${name} to this school.<br/>Please, login to ${name} Stripe account before click "Start" button.`,
      connectName: ''
    });
  };

  const performStandartStripeConnect = () => {
    setConnectModalState({ ...connectModalState, isOperating: true });
    localStorage.setItem('connectOrgId', orgId);
    localStorage.setItem('centerId', connectModalState.id);
    localStorage.setItem('connectName', connectModalState.connectName);

    apiService
      .post(`/stripe/connect/fromSchool/${orgId}/${connectModalState.id}`, { name: connectModalState.connectName })
      .then(
        (result: any) => {
          window.location.href = result.data;
        },
        (error) => {
          showNotification(false, error.response.data.message as string, true);
        }
      );
  };

  const performStandartStripeDisconnect = (currentConfirmationModalState) => {
    apiService.post(`/stripe/connect/fromSchool/${orgId}/disconnect/${currentConfirmationModalState.orgId}`, {}).then(
      (result: any) => {
        setConfirmationModalState({ ...defaultConfirmationModalState });
        getCenters({ pageIndex: page, pageSize: rowsPerPage });
        showNotification(true, result.data.message as string, false);
      },
      (error) => {
        setConfirmationModalState({ ...currentConfirmationModalState, isOperating: false });
        showNotification(false, error.response.data.message as string, true);
      }
    );
  };

  const defaultConfirmationModalState = {
    isOpen: false,
    isOperating: false,
    message: '',
    name: '',
    orgId: null,
    transferId: null,
    onConfirm: null
  };
  const [confirmationModalState, setConfirmationModalState] = useState(defaultConfirmationModalState);

  const onDisconnectCLick = (centerId: string, name: string) => {
    setConfirmationModalState({
      isOpen: true,
      isOperating: false,
      message:
        'You are about to Disconnect Efacity from this school.<br/>' +
        '<div style="display:flex;justify-content:center">Please, confirm.</div>',
      name: '',
      orgId: centerId,
      transferId: null,
      onConfirm: performStandartStripeDisconnect
    });
  };

  const onDeleteCenterCLick = (centerId: string, name: string) => {
    setConfirmationModalState({
      isOpen: true,
      isOperating: false,
      message:
        `You are about to delete ${name} center.<br/>` +
        '<div style="display:flex;justify-content:center">Please, confirm.</div>',
      name: '',
      orgId: centerId,
      transferId: null,
      onConfirm: performDeleteCenter
    });
  };

  const performDeleteCenter = (currentConfirmationModalState) => {
    setConfirmationModalState({ ...currentConfirmationModalState, isOperating: true });
    apiService.delete(`/org/${orgId}/schools/centers/${currentConfirmationModalState.orgId}`).then(
      (result: any) => {
        setConfirmationModalState({ ...defaultConfirmationModalState });
        getCenters({ pageIndex: page, pageSize: rowsPerPage });
        showNotification(true, result.data.message as string, false);
      },
      (error) => {
        setConfirmationModalState({ ...currentConfirmationModalState, isOpen: false, isOperating: false });
        showNotification(false, error.response.data.message as string, true);
      }
    );
  };

  const addButtonLink = orgId ? toPath(PATHS.addCenter, { orgId: orgId }) : PATHS.addCenter;

  return (
    <Box>
      <PageTitle title="Centers" addButtonLink={addButtonLink} />
      <TableContainer component={Paper} sx={centersContainerSx.tableContainer}>
        <Table size="small" aria-label="a simple dense table" sx={centersContainerSx.table}>
          <TableHead style={centersContainerSx.tableHead}>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell align="left" sx={centersContainerSx.hiddenSm}>
                Phone
              </TableCell>
              <TableCell align="left" style={{ maxWidth: 100 }}>
                Connect Status
              </TableCell>
              <TableCell align="left" style={{ maxWidth: 100 }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {centersState.loadingCentersList ? (
              <TableRow>
                <TableCell align="center" colSpan={3}>
                  <LinearProgress variant="indeterminate" color="primary" data-testid="loadingIndicator" />
                </TableCell>
              </TableRow>
            ) : (
              centersState.centers.map((center: Center) => (
                <TableRow key={center._id} hover data-testid="table-row">
                  <TableCell align="left" style={{ ...adminSectionTableRowDefaultStyle }}>
                    <Link
                      component={NavLink}
                      to={toPath(PATHS.editCenter, { orgId: orgId, id: center._id })}
                      underline={'none'}
                    >
                      {center.name}
                    </Link>
                  </TableCell>
                  <TableCell align="left" sx={centersContainerSx.hiddenSm}>
                    <PhoneNumber phone={center.phone} />
                  </TableCell>
                  <TableCell align="left">{connectStatusViews[center.connectStatus]}</TableCell>
                  <TableCell align="right" style={{ width: 180, maxWidth: 180 }}>
                    <div style={{ display: 'flex', justifyItems: 'center' }}>
                      <div style={{ display: 'flex', justifyItems: 'center' }}>
                        <CircularProgressButton
                          progress={false}
                          color="primary"
                          onClick={() => {
                            center.connectStatus === ConnectStatus.ConnectedStandard
                              ? onDisconnectCLick(center._id, center.name)
                              : onConnectCLick(center._id, center.name);
                          }}
                          style={{ ...smallPublishButtonStyle, width: 100 }}
                          data-testid={`connectButtonForCenter${center._id}`}
                          disabled={connectModalState.isOperating}
                        >
                          {center.connectStatus === ConnectStatus.ConnectedStandard ? 'Disconnect' : 'Connect'}
                        </CircularProgressButton>
                      </div>
                      <IconButton
                        color="primary"
                        aria-label="delete center"
                        data-testid="delete-center-btn"
                        component="span"
                        style={{ ...smallPublishButtonStyle, width: 45 }}
                        onClick={() => onDeleteCenterCLick(center._id, center.name)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                colSpan={3}
                rowsPerPageOptions={[10]}
                count={centersState.total}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      {connectModalState.isOpen && (
        <StartConnectModal
          open={connectModalState.isOpen}
          onClose={() => setConnectModalState({ ...defaultConnectModalState, isOpen: false })}
          onStart={performStandartStripeConnect}
          message={connectModalState.message}
          connectName={connectModalState.connectName}
          onNameChanged={(event) => setConnectModalState({ ...connectModalState, connectName: event.target.value })}
        />
      )}
      {confirmationModalState.isOpen && (
        <ConfirmationModal
          open={confirmationModalState.isOpen}
          isOperating={confirmationModalState.isOperating}
          message={confirmationModalState.message}
          onClose={() => setConfirmationModalState({ ...defaultConfirmationModalState })}
          onConfirm={() => confirmationModalState.onConfirm(confirmationModalState)}
        />
      )}
    </Box>
  );
};
