import React, { FC } from 'react';
import { apiService } from '@efacity/frontend-shared';
import { Messages } from '@efacity/common';
import { IdOrgIdParamTypes } from '@efacity/frontend-next-shared/utils';
import { useParams } from 'react-router-dom';
import Table, { PaginationTypes } from '../../../components/Table/Table';
import { useSessionOptionsTableColumns } from '../useSessionOptionsTableColumns';
import { useAdditionalSessionOptionsLoader } from '../../../hooks/useAdditionalSessionOptionLoader';
import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/DeleteForever';
import { smallPublishButtonStyle } from '../../../components/Buttons/SessionPublishButton';
import { InstanceTemporality } from '@efacity/common';
import { showNotification } from '@efacity/frontend-next-shared/notifications';

const CurrentSessionOptionsTable: FC = () => {
  const { orgId } = useParams<IdOrgIdParamTypes>();

  const { additionalSessionOptionsState, getAdditionalSessionOptions } = useAdditionalSessionOptionsLoader(
    `/org/${orgId}/additionalOptions/${InstanceTemporality.Current}`
  );
  const columns = useSessionOptionsTableColumns(orgId, [
    {
      id: 'delete-additional-option',
      columnStyles: { width: 75, maxWidth: 75 },
      Cell: ({ row }) => {
        return (
          <IconButton
            color="primary"
            aria-label="delete additional option"
            data-testid="delete-session-option"
            component="span"
            disabled={new Date(row.original.expiresOn) < new Date()}
            style={smallPublishButtonStyle}
            onClick={() => handleDeleteAdditionalOption(row.original._id)}
          >
            <DeleteIcon />
          </IconButton>
        );
      }
    }
  ]);

  function handleDeleteAdditionalOption(additionalOptionId) {
    apiService.delete(`/org/${orgId}/additionalOption/${additionalOptionId}`).then(
      (result: any) => {
        showNotification(true, result.data.message as string, false);
        getAdditionalSessionOptions();
      },
      (error) => {
        showNotification(false, (error.response.data.message as string) || Messages.FailedDeleteAdditionalOption, true);
      }
    );
  }

  return (
    <Table
      columns={columns}
      data={additionalSessionOptionsState.data}
      loading={additionalSessionOptionsState.isLoading}
      paginationType={PaginationTypes.ClientSide}
      noDataText="No current additional options..."
    />
  );
};

export default CurrentSessionOptionsTable;
