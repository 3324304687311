import { apiService } from '@efacity/frontend-shared';
import { useCallback, useState } from 'react';
import { showNotification } from '@efacity/frontend-next-shared/notifications';
import { Messages, User } from '@efacity/common';
import { getFiltersQueryParameters, mapReactTableSortToApiSort } from '../../utils/queryHelpers';
import { FetchDataOptions } from '../../components/Table/Table';

export interface UsersResponse {
  data: User[];
  total?: number;
}

export interface UsersState {
  users: User[];
  total: number;
  isLoading: boolean;
}

export const useSiteAdminUsersLoader = (url: string) => {
  const [usersState, setUsersState] = useState<UsersState>({
    users: [],
    total: 0,
    isLoading: true
  });

  const getUsers = useCallback(
    ({ pageIndex, pageSize, filters, sortBy }: FetchDataOptions) => {
      const setUsers = ({ data, total }: UsersResponse, isLoading: boolean) => {
        setUsersState({
          users: data,
          total: total,
          isLoading: isLoading
        });
      };

      const showErrorNotification = (message: string) => {
        setUsers({ data: [], total: 0 }, false);
        showNotification(false, message, true);
      };

      apiService
        .get<UsersResponse>(url, {
          page: pageIndex,
          perPage: pageSize,
          ...getFiltersQueryParameters(filters),
          sortBy: mapReactTableSortToApiSort(sortBy)
        })
        .then(
          (result) => {
            return setUsers(result.data, false);
          },
          (error) => {
            setUsersState((usersState) => ({
              ...usersState,
              isLoading: false
            }));
            showErrorNotification((error.response?.data?.message as string) || Messages.FailedGetUsers);
          }
        );
    },
    [url]
  );
  return { usersState, getUsers };
};
