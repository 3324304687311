import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { apiService } from '@efacity/frontend-shared';
import { Student } from '@efacity/common';
import Table, { FetchDataOptions, initialFetchDataOptions, PaginationTypes } from '../../components/Table/Table';
import { OrgIdParamType } from '@efacity/frontend-next-shared/utils';
import { getFiltersQueryParameters, mapReactTableSortToApiSort } from '../../utils/queryHelpers';
import EditCustomerModal from '../../components/EditCustomerModal/EditCustomerModal';
import useCustomersTableColumns from './useCustomersTableColumns';
import { debounce } from 'lodash';
import { showNotification } from '@efacity/frontend-next-shared/notifications';
import { PageTitle } from '@efacity/react-next-sc';

export interface CustomersStateInterface {
  customers: Student[];
  total: number;
  isLoading: boolean;
  fetchDataOptions: FetchDataOptions;
}
export interface CustomerUnderEditState {
  customerId: string;
  fetchDataOptions: FetchDataOptions;
}
const emptyCustomerUnderEdit = {
  customerId: '',
  fetchDataOptions: initialFetchDataOptions
};
export interface CustomersResponse {
  users: Student[];
  total?: number;
}

const OrganizationCustomersTable: React.FC = () => {
  const { orgId } = useParams<OrgIdParamType>();

  const [customersState, setCustomersState] = useState<CustomersStateInterface>({
    customers: [],
    total: 0,
    isLoading: false,
    fetchDataOptions: initialFetchDataOptions
  });
  const [customerUnderEdit, setCustomerUnderEdit] = useState<CustomerUnderEditState>(emptyCustomerUnderEdit);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getCustomers = useCallback(
    debounce(async (fetchDataOptions: FetchDataOptions) => {
      setCustomersState((state) => ({
        ...state,
        isLoading: true
      }));

      const { pageIndex, pageSize, filters, sortBy } = fetchDataOptions;
      const filtersQueryParams = getFiltersQueryParameters(filters);
      const sortingQueryParams = mapReactTableSortToApiSort(sortBy);

      try {
        const { data } = await apiService.get<CustomersResponse>(`/org/${orgId}/users/customers`, {
          page: pageIndex,
          perPage: pageSize,
          ...filtersQueryParams,
          sortBy: sortingQueryParams
        });
        setCustomersState((state) => ({
          ...state,
          customers: data.users,
          total: data.total,
          isLoading: false
        }));
      } catch {
        showNotification(false, 'Failed to load students', true);
        setCustomersState((state) => ({
          ...state,
          isLoading: false
        }));
      }
    }, 400),
    [orgId]
  );

  const onEditCustomerInfo = (customerId: string, fetchDataOptions: FetchDataOptions) => {
    setCustomerUnderEdit({
      customerId: customerId,
      fetchDataOptions: fetchDataOptions
    });
  };
  const handleCustomerModalClose = async (reloadData: boolean) => {
    setCustomerUnderEdit((state) => {
      if (reloadData) {
        getCustomers(state.fetchDataOptions);
      }
      return emptyCustomerUnderEdit;
    });
  };
  const columns = useCustomersTableColumns(onEditCustomerInfo);

  return (
    <div>
      <PageTitle title="Customers" addButtonLink={undefined} />
      <Table
        columns={columns}
        data={customersState.customers}
        loading={customersState.isLoading}
        fetchData={getCustomers}
        paginationType={PaginationTypes.ServerSide}
        pageCount={customersState.total}
        noDataText="No customers..."
      />
      {customerUnderEdit.customerId && (
        <EditCustomerModal orgId={orgId} customerId={customerUnderEdit.customerId} onClose={handleCustomerModalClose} />
      )}
    </div>
  );
};

export default OrganizationCustomersTable;
