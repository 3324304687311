import React, { useCallback, useState } from 'react';
import { apiService, ConfirmationModal } from '@efacity/frontend-shared';
import { showNotification } from '@efacity/frontend-next-shared/notifications';
import { BaseUser, Messages, OwnerType } from '@efacity/common';
import Table, { FetchDataOptions, initialFetchDataOptions, PaginationTypes } from '../../../components/Table/Table';
import { getFiltersQueryParameters, mapReactTableSortToApiSort } from '../../../utils/queryHelpers';
import useOrganizationAdminsTableColumns from './useSchoolAdminsTableColumns';
import UserEditModal from '../UserEditModal/UserEditModal';
import { debounce } from 'lodash';
import { NewButton } from '@efacity/frontend-next-shared/org-settings';
import AddNewAdminModal from './AddNewAdminModal';

export interface SchoolAdminsStateInterface {
  admins: BaseUser[];
  total: number;
  isLoading: boolean;
  fetchDataOptions: FetchDataOptions;
}
export interface UsersResponse {
  users: BaseUser[];
  total?: number;
}
export const emptyUserUnderEdit = {
  userId: '',
  fetchDataOptions: initialFetchDataOptions
};

export interface OrganizationAdminsTableProps {
  authenticatedUserId: string;
  orgId: string;
  orgType: OwnerType;
}

const OrganizationAdminsTableContainer: React.FC<OrganizationAdminsTableProps> = ({
  authenticatedUserId,
  orgId,
  orgType
}) => {
  const [addModalOpen, setAddModalOpen] = useState(false);

  const handleAddModalClose = async () => {
    setAddModalOpen(false);
  };

  const [schoolAdminsState, setSchoolAdminsState] = useState<SchoolAdminsStateInterface>({
    admins: [],
    total: 0,
    isLoading: false,
    fetchDataOptions: initialFetchDataOptions
  });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getSchoolAdmins = useCallback(
    debounce(async (fetchDataOptions: FetchDataOptions) => {
      setSchoolAdminsState((state) => ({
        ...state,
        isLoading: true
      }));

      const { pageIndex, pageSize, filters, sortBy } = fetchDataOptions;
      const filtersQueryParams = getFiltersQueryParameters(filters);
      const sortingQueryParams = mapReactTableSortToApiSort(sortBy);

      const url =
        orgType === OwnerType.School ? `/org/${orgId}/users/school-admins` : `/org/${orgId}/users/center-admins`;
      try {
        const { data } = await apiService.get<UsersResponse>(url, {
          page: pageIndex,
          perPage: pageSize,
          ...filtersQueryParams,
          sortBy: sortingQueryParams
        });
        setSchoolAdminsState((state) => ({
          ...state,
          admins: data.users,
          total: data.total,
          isLoading: false
        }));
      } catch {
        showNotification(false, 'Failed to load admins', true);
        setSchoolAdminsState((state) => ({
          ...state,
          isLoading: false
        }));
      }
    }, 400),
    [orgId, addModalOpen]
  );

  const deleteAdmin = async (userId: string, fetchDataOptions: FetchDataOptions) => {
    setConfirmationModalState((state) => {
      return { ...state, isOperating: true };
    });
    try {
      await apiService.delete<{ message: string }>(`/org/${orgId}/admins/${userId}`);
      await getSchoolAdmins(fetchDataOptions);
      setConfirmationModalState(defaultConfirmationModalState);
    } catch {
      showNotification(false, Messages.FailedDeleteAdministrator, true);
      setConfirmationModalState(defaultConfirmationModalState);
    }
  };
  const defaultConfirmationModalState = {
    isOpen: false,
    isOperating: false,
    message: '',
    onConfirm: null
  };
  const [confirmationModalState, setConfirmationModalState] = useState(defaultConfirmationModalState);

  const onDeleteAdminCLick = (userId: string, name: string, fetchDataOptions: FetchDataOptions) => {
    setConfirmationModalState({
      isOpen: true,
      isOperating: false,
      message:
        `<div style="display:flex;justify-content:center">You are about to delete administrator ${name}.</div><br/>` +
        '<div style="display:flex;justify-content:center">Please, confirm.</div>',
      onConfirm: () => deleteAdmin(userId, fetchDataOptions)
    });
  };

  const [userUnderEdit, setUserUnderEdit] = useState(emptyUserUnderEdit);
  const onEditAdminClick = (userId: string, fetchDataOptions: FetchDataOptions) => {
    setUserUnderEdit({
      userId: userId,
      fetchDataOptions: fetchDataOptions
    });
  };
  const handleUserModalClose = async (reloadData: boolean) => {
    setUserUnderEdit((state) => {
      if (reloadData) {
        getSchoolAdmins(state.fetchDataOptions);
      }
      return emptyUserUnderEdit;
    });
  };

  const columns = useOrganizationAdminsTableColumns(orgId, onDeleteAdminCLick, onEditAdminClick, authenticatedUserId);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', marginTop: '15px' }}>
      <NewButton
        style={{ width: '120px', marginBottom: '10px', alignSelf: 'end' }}
        onClick={() => {
          setAddModalOpen(true);
        }}
      >
        New
      </NewButton>
      <Table
        columns={columns}
        data={schoolAdminsState.admins}
        loading={schoolAdminsState.isLoading}
        fetchData={getSchoolAdmins}
        paginationType={PaginationTypes.ServerSide}
        pageCount={schoolAdminsState.total}
        noDataText="No admins..."
      />
      {confirmationModalState.isOpen && (
        <ConfirmationModal
          open={confirmationModalState.isOpen}
          isOperating={confirmationModalState.isOperating}
          message={confirmationModalState.message}
          onClose={() => setConfirmationModalState(defaultConfirmationModalState)}
          onConfirm={() => confirmationModalState.onConfirm(confirmationModalState)}
        />
      )}
      {userUnderEdit.userId && (
        <UserEditModal
          open={!!userUnderEdit.userId}
          orgId={orgId}
          userId={userUnderEdit.userId}
          onClose={handleUserModalClose}
        />
      )}
      <AddNewAdminModal orgId={orgId} isOpen={addModalOpen} onClose={handleAddModalClose} />
    </div>
  );
};

export default OrganizationAdminsTableContainer;
