import { Activity, ActivityTypes, InstanceTemporality, Messages, SessionStatus } from '@efacity/common';
import { apiService } from '@efacity/frontend-shared';
import { useCallback, useState } from 'react';
import { CancelActions } from './CancellationModal';
import { debounce } from 'lodash';
import { FetchOptionsWithFromToDatesState } from '../Attendance/useAttendanceLoader';
import { getFiltersQueryParameters, mapReactTableSortToApiSort } from '../../utils/queryHelpers';
import { showNotification } from '@efacity/frontend-next-shared/notifications';
import { Session as EfacitySession } from '@efacity/react-next-sc';

export interface SessionActionsState {
  isLoadingSession: boolean;
  isUpdating: boolean;
  session: EfacitySession | null;
  requireStudentAge: boolean;
}
export const defaultSessionActionsState: SessionActionsState = {
  isLoadingSession: false,
  isUpdating: false,
  session: null,
  requireStudentAge: false
};
export interface SessionsListActionsState {
  list: EfacitySession[];
  isLoadingSessionsList: boolean;
  total: number;
  isImporting: boolean;
  isUpdating: boolean;
}
export const defaultSessionsListActionState = {
  list: [],
  isLoadingSessionsList: false,
  total: 0,
  isImporting: false,
  isUpdating: false
};

export const useSessionTabActions = (orgId: string) => {
  const [sessionActionsState, setUpdatingSessionState] = useState<SessionActionsState>(defaultSessionActionsState);
  const [sessionsListActionState, setSessionsLisActionState] =
    useState<SessionsListActionsState>(defaultSessionsListActionState);

  const [cancellationModalOpened, setCancellationModalOpened] = useState<boolean>(false);
  const [modalCallbackAdditionalProps, setModalCallbackAdditionalProps] = useState({ sessionId: null });

  const loadSession = async (sessionId: string) => {
    setUpdatingSessionState((state) => ({
      ...state,
      isLoadingSession: true
    }));
    try {
      const { data } = await apiService.get<{ session: EfacitySession; requireStudentAge: boolean }>(
        `/org/${orgId}/sessions/${sessionId}`
      );

      const requireStudentAgeForSession =
        data.requireStudentAge && data.session.type !== ActivityTypes.InstructorCertification;

      setUpdatingSessionState((state) => ({
        ...state,
        session: data.session,
        requireStudentAge: requireStudentAgeForSession,
        isLoadingSession: false
      }));
    } catch {
      showNotification(false, Messages.FailedGetSession);
    } finally {
      setUpdatingSessionState((state) => ({
        ...state,
        isLoadingSession: false
      }));
    }
  };

  const loadActivityValuesForSession = async (activityId: string) => {
    setUpdatingSessionState((state) => ({
      ...state,
      isLoadingSession: true
    }));
    try {
      const { data } = await apiService.get<Activity>(`/org/${orgId}/activity/${activityId}`);
      const activityAsSessionFormValues = {
        ...(data as unknown as EfacitySession),
        activityId: data._id,
        price: data.defaultSessionPrice,
        tags: []
      };

      setUpdatingSessionState((state) => ({
        ...state,
        session: activityAsSessionFormValues,
        isLoadingSession: false
      }));
    } catch {
      showNotification(false, Messages.FailedGetSession);
    } finally {
      setUpdatingSessionState((state) => ({
        ...state,
        isLoadingSession: false
      }));
    }
  };

  const updateCancellationInSession = (session: EfacitySession, updatedSession: EfacitySession) => ({
    ...session,
    cancelledAt: updatedSession?.cancelledAt,
    cancelledBy: updatedSession?.cancelledBy,
    publishedAt: updatedSession?.publishedAt,
    cancellationReason: updatedSession?.cancellationReason
  });

  const onCancelSession = async (
    sessionId: string,
    cancellationReason: string,
    cancelSessionActions: CancelActions
  ) => {
    setUpdatingSessionState((state) => ({
      ...state,
      isUpdating: true
    }));
    try {
      const result = await apiService.patch<{ session: EfacitySession; message: string }>(
        `/org/${orgId}/sessions/${sessionId}/cancel`,
        { cancellationReason }
      );
      cancelSessionActions.setCancellationReason('');
      if (sessionActionsState.session) {
        setUpdatingSessionState((state) => ({
          ...state,
          session: { ...updateCancellationInSession(state.session, result.data.session) }
        }));
      }
      if (sessionsListActionState.list?.length > 0) {
        const sessionIndex = sessionsListActionState.list.findIndex((item) => item._id === sessionId);
        if (sessionIndex > -1) {
          const foundSession = sessionsListActionState.list[sessionIndex];
          sessionsListActionState.list[sessionIndex] = {
            ...updateCancellationInSession(foundSession, result.data.session)
          };
        }
      }
      setCancellationModalOpened(false);
      if (result.status === 202) {
        showNotification(false, result.data?.message, true, 'Ok');
      }
    } catch (error) {
      if (error.response?.data?.validationErrors) {
        cancelSessionActions.setValidationError(
          error.response?.data?.validationErrors?.cancellationReason || Messages.FailedToCancelSession
        );
      } else {
        showNotification(false, Messages.FailedToCancelSession);
      }
    } finally {
      setUpdatingSessionState((state) => ({
        ...state,
        isUpdating: false
      }));
    }
  };

  const onRestoreSession = async (sessionId: string) => {
    setUpdatingSessionState((state) => ({
      ...state,
      isUpdating: true
    }));
    try {
      const result = await apiService.patch<{ session: EfacitySession; message: string }>(
        `/org/${orgId}/sessions/${sessionId}/restore`,
        {}
      );
      if (sessionActionsState.session) {
        setUpdatingSessionState((state) => ({
          ...state,
          session: { ...updateCancellationInSession(state.session, result.data.session) }
        }));
      }
      if (sessionsListActionState.list?.length > 0) {
        const sessionIndex = sessionsListActionState.list.findIndex((item) => item._id === sessionId);
        if (sessionIndex > -1) {
          const foundSession = sessionsListActionState.list[sessionIndex];
          sessionsListActionState.list[sessionIndex] = {
            ...updateCancellationInSession(foundSession, result.data.session)
          };
        }
      }
      setCancellationModalOpened(false);
      if (result.status === 202) {
        showNotification(false, result.data?.message, true, 'Ok');
      }
    } catch {
      showNotification(false, Messages.FailedRestoreSession);
    } finally {
      setUpdatingSessionState((state) => ({
        ...state,
        isUpdating: false
      }));
    }
  };

  const updateStatusInSession = (session: EfacitySession, newStatus: SessionStatus) => ({
    ...session,
    status: newStatus
  });

  const onChangeSessionStatus = async (sessionId: string, sessionStatus: SessionStatus, setRowState) => {
    setUpdatingSessionState((state) => ({
      ...state,
      isUpdating: true
    }));

    setRowState && setRowState((rowState) => ({ ...rowState, isLoading: true }));

    try {
      const result = await apiService.patch<{ session: EfacitySession; message: string }>(
        `/org/${orgId}/sessions/${sessionId}/status`,
        {
          sessionStatus
        }
      );

      if (sessionActionsState.session) {
        setUpdatingSessionState((state) => ({
          ...state,
          session: { ...updateStatusInSession(state.session, result.data.session?.status) }
        }));
      }
      if (sessionsListActionState.list?.length > 0) {
        const sessionIndex = sessionsListActionState.list.findIndex((item) => item._id === sessionId);
        if (sessionIndex > -1) {
          const foundSession = sessionsListActionState.list[sessionIndex];
          const updatedSession = { ...updateStatusInSession(foundSession, result.data.session?.status) };
          sessionsListActionState.list[sessionIndex] = updatedSession;
        }
      }
      if (result.status === 202) {
        showNotification(true, result.data?.message, true, 'Ok');
      }
    } catch (error) {
      showNotification(false, error?.message || Messages.FailedChangeSessionStatus);
    }

    setUpdatingSessionState((state) => ({
      ...state,
      isUpdating: false
    }));
    setRowState && setRowState((rowState) => ({ ...rowState, isLoading: false }));
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchSessions = useCallback(
    debounce(async (sessionType: InstanceTemporality, fetchOptionsWithDates: FetchOptionsWithFromToDatesState) => {
      setSessionsLisActionState((state) => ({
        ...state,
        isLoadingSessionsList: true
      }));

      try {
        const { fetchDataOptions, fromDate, toDate } = fetchOptionsWithDates;
        const { filters, pageIndex, pageSize, sortBy } = fetchDataOptions;
        const sessionsQueryParams =
          sessionType === InstanceTemporality.Completed
            ? {
                fromDate,
                toDate,
                page: pageIndex,
                perPage: pageSize,
                ...getFiltersQueryParameters(filters),
                sortBy: mapReactTableSortToApiSort(sortBy || [])
              }
            : {
                page: pageIndex,
                perPage: pageSize,
                ...getFiltersQueryParameters(filters),
                sortBy: mapReactTableSortToApiSort(sortBy || [])
              };

        const { data } = await apiService.get<{ data: EfacitySession[]; total: number }>(
          `/org/${orgId}/sessions/${sessionType}/list`,
          sessionsQueryParams
        );

        setSessionsLisActionState((state) => ({
          ...state,
          list: data.data,
          total: data.total,
          isLoadingSessionsList: false
        }));
      } catch (error) {
        setSessionsLisActionState((state) => ({
          ...state,
          isLoadingSessionsList: false
        }));
        showNotification(false, error.message as string, true);
      }
    }, 400),
    [orgId]
  );

  const onOpenCancellationModal = () => {
    setCancellationModalOpened(true);
  };

  const onCloseCancellationModal = () => {
    setCancellationModalOpened(false);
  };

  return [
    { sessionActionsState, cancellationModalOpened, modalCallbackAdditionalProps, sessionsListActionState },
    {
      fetchSessions,
      loadSession,
      loadActivityValuesForSession,
      setCancellationModalOpened,
      onOpenCancellationModal,
      onCloseCancellationModal,
      onCancelSession,
      onRestoreSession,
      onChangeSessionStatus,
      setModalCallbackAdditionalProps,
      setUpdatingSessionState
    }
  ] as const;
};
