import React, { useCallback, useMemo, useState } from 'react';
import { apiService, Certificate } from '@efacity/frontend-shared';
import { OrgIdParamType } from '@efacity/frontend-next-shared/utils';
import { NavLink, useParams } from 'react-router-dom';
import Table, { EnhancedColumn, FetchDataOptions, PaginationTypes } from '../../components/Table/Table';
import EmailAddress from '../../components/EmailAddress/EmailAddress';
import { certificateTypesMapper, Messages, formatStringToMonthDayYearDate } from '@efacity/common';
import { PATHS, toPath } from '@efacity/routing';
import { Link } from '@mui/material';
import { getFiltersQueryParameters, mapReactTableSortToApiSort } from '../../utils/queryHelpers';
import { debounce } from 'lodash';
import { showNotification } from '@efacity/frontend-next-shared/notifications';
import { PageTitle } from '@efacity/react-next-sc';

interface OrganizationCertificatesTableData {
  data: Certificate[];
  total: number;
}

const OrganizationCertificatesContainer: React.FC = () => {
  const { orgId } = useParams<OrgIdParamType>();
  const fetchDataUrl = `/org/${orgId}/certificates`;

  const [certificatesData, setCertificatesData] = useState({
    data: [],
    total: 0,
    isLoading: false
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getDataDebounced = useCallback(
    debounce(async ({ filters, pageIndex, pageSize, sortBy }: FetchDataOptions) => {
      try {
        const { data } = await apiService.get<OrganizationCertificatesTableData>(fetchDataUrl, {
          page: pageIndex,
          perPage: pageSize,
          ...getFiltersQueryParameters(filters),
          sortBy: mapReactTableSortToApiSort(sortBy || [])
        });
        setCertificatesData((state) => ({ data: data.data, total: data.total, isLoading: false }));
      } catch (error) {
        setCertificatesData((state) => ({ ...state, isLoading: false }));
        showNotification(false, error.response?.data?.message || Messages.FailedGetRegistrations);
      }
    }, 400),
    [orgId]
  );

  const getCertificates = useCallback(
    (fetchDataOptions: FetchDataOptions) => {
      setCertificatesData((state) => ({ ...state, isLoading: true }));
      getDataDebounced(fetchDataOptions);
    },
    [getDataDebounced]
  );

  const columns = useMemo(() => {
    const columns: EnhancedColumn<any>[] = [
      {
        Header: 'Instructor Name',
        accessor: 'user.fullName',
        Cell: ({ value }) => {
          return value || '[n/a]';
        },
        disableFilters: false,
        disableSortBy: false
      },
      {
        Header: 'Email',
        accessor: 'user.email',
        Cell: ({ value }) => {
          return value ? <EmailAddress email={value} /> : <>[n/a]</>;
        },
        disableFilters: false,
        disableSortBy: false
      },
      {
        Header: 'Certificate Type',
        accessor: (data) => {
          return certificateTypesMapper[data?.type];
        },
        Cell: ({ value }) => {
          return value;
        },
        disableFilters: true
      },
      {
        Header: 'Activity Name',
        accessor: 'certificationForActivity.name',
        Cell: ({ row }) => {
          return (
            <Link
              component={NavLink}
              to={`${toPath(PATHS.editActivity, {
                orgId: orgId,
                id: row.original.certificationForActivity._id
              })}/certificates`}
              underline={'none'}
            >
              {row.original.certificationForActivity?.name || '[n/a]'}
            </Link>
          );
        },
        disableFilters: false,
        disableSortBy: false
      },
      {
        Header: 'Expires On',
        accessor: 'expiresOn',
        disableFilters: true,
        Cell: ({ value: expiresOn }) => <>{formatStringToMonthDayYearDate(expiresOn)}</>
      }
    ];

    return columns;
  }, [orgId]);

  return (
    <div>
      <PageTitle title="Certificates" />
      <Table
        columns={columns}
        data={certificatesData.data}
        loading={certificatesData.isLoading}
        fetchData={getCertificates}
        pageCount={certificatesData.total}
        paginationType={PaginationTypes.ServerSide}
        noDataText="No current certificates..."
      />
    </div>
  );
};

export default OrganizationCertificatesContainer;
