import React, { useState } from 'react';
import { NewButton } from '@efacity/frontend-next-shared/org-settings';
import { useFetch } from '@efacity/frontend-shared';
import { useParams } from 'react-router-dom';
import { OrgIdParamType } from '@efacity/frontend-next-shared/utils';
import { Agreement, Messages } from '@efacity/common';
import AgreementModal from './AgreementModal/AgreementModal';
import { apiService } from '@efacity/frontend-shared';
import Table from '../../components/Table/Table';
import useCustomerAgreementsTableColumns from './useCustomerAgreementsTableColumns';
import { showNotification } from '@efacity/frontend-next-shared/notifications';
import { PageTitle } from '@efacity/react-next-sc';

const CustomerAgreementsContainer = () => {
  const { orgId } = useParams<OrgIdParamType>();

  const [{ data: agreements, isLoading }, { fetchData }] = useFetch<Agreement[]>(`/org/${orgId}/agreements`, {
    initialDataState: [],
    errorMessage: Messages.CannotFetchAgreements
  });
  const [agreementModalOpen, setAgreementModalOpen] = useState(false);
  const [agreementToEdit, setAgreementToEdit] = useState(null);

  const handleShowAgreementModal = (agreementId?: string) => {
    setAgreementModalOpen(true);
    if (agreementId) {
      setAgreementToEdit(agreementId);
    }
  };
  const handleModalClose = (needReload: boolean) => {
    setAgreementModalOpen(false);
    setAgreementToEdit(null);

    if (needReload) fetchData(`/org/${orgId}/agreements`);
  };

  const handleDeleteAgreement = async (agreementId: string) => {
    try {
      await apiService.delete(`/org/${orgId}/agreements/${agreementId}`);
      fetchData(`/org/${orgId}/agreements`);
    } catch (e) {
      showNotification(false, e?.response?.data?.message || Messages.UnableDeleteAgreement);
    }
  };

  const columns = useCustomerAgreementsTableColumns(handleShowAgreementModal, handleDeleteAgreement);

  return (
    <div style={{ marginTop: '15px' }}>
      <PageTitle
        title=""
        button={
          <NewButton
            onClick={() => handleShowAgreementModal()}
            data-testid="add-agreement"
            style={{ width: 120, height: 36, textTransform: 'uppercase' }}
          >
            New
          </NewButton>
        }
      />
      <Table columns={columns} data={agreements} loading={isLoading} noDataText="No agreements..." />
      {agreementModalOpen && (
        <AgreementModal
          agreementId={agreementToEdit}
          orgId={orgId}
          open={agreementModalOpen}
          onClose={handleModalClose}
        />
      )}
    </div>
  );
};

export default CustomerAgreementsContainer;
