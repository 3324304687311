import { apiService } from '@efacity/frontend-shared';
import { useCallback, useState } from 'react';
import { showNotification } from '@efacity/frontend-next-shared/notifications';
import { Messages, User } from '@efacity/common';
import { FetchDataOptions } from '../../components/Table/Table';
import { getFiltersQueryParameters, mapReactTableSortToApiSort } from '../../utils/queryHelpers';

export interface InstructorsResponse {
  users: User[];
  total?: number;
}

export interface InstructorsState {
  instructors: User[];
  isLoading: boolean;
  total: number;
}

export const useInstructorsLoader = (url: string) => {
  const [instructorsState, setInstructorsState] = useState<InstructorsState>({
    instructors: [],
    total: 0,
    isLoading: true
  });

  const getInstructors = useCallback(
    ({ pageIndex, pageSize, filters, sortBy }: FetchDataOptions) => {
      const setInstructors = ({ users, total }: InstructorsResponse, isLoading: boolean) => {
        setInstructorsState({
          instructors: users,
          total: total,
          isLoading: isLoading
        });
      };

      const showErrorNotification = (message: string) => {
        setInstructors({ users: [], total: 0 }, false);
        showNotification(false, message, true);
      };

      apiService
        .get<InstructorsResponse>(url, {
          page: pageIndex,
          perPage: pageSize,
          ...getFiltersQueryParameters(filters),
          sortBy: mapReactTableSortToApiSort(sortBy)
        })
        .then(
          (result) => {
            return setInstructors(result.data, false);
          },
          (error) => {
            setInstructorsState((instructorsState) => ({
              ...instructorsState,
              isLoading: false
            }));
            showErrorNotification((error.response?.data?.message as string) || Messages.FailedGetInstructors);
          }
        );
    },
    [url]
  );
  return { instructorsState, getInstructors };
};
