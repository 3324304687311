import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { apiService, ConfirmationModal } from '@efacity/frontend-shared';
import { BaseUser, Messages } from '@efacity/common';
import Table, { FetchDataOptions, initialFetchDataOptions, PaginationTypes } from '../../../components/Table/Table';
import { OrgIdParamType } from '@efacity/frontend-next-shared/utils';
import { getFiltersQueryParameters, mapReactTableSortToApiSort } from '../../../utils/queryHelpers';
import useCenterAdminsTableColumns from './useCenterAdminsTableColumns';
import UserEditModal from '../UserEditModal/UserEditModal';
import { debounce } from 'lodash';
import { showNotification } from '@efacity/frontend-next-shared/notifications';

export interface CenterAdminsStateInterface {
  admins: BaseUser[];
  total: number;
  isLoading: boolean;
  fetchDataOptions: FetchDataOptions;
}
export const emptyCenterAdminUnderEdit = {
  userId: '',
  centerOrgId: '',
  fetchDataOptions: initialFetchDataOptions
};
export interface UsersResponse {
  users: BaseUser[];
  total?: number;
}
export interface CenterAdminsTableProps {
  hasSchoolAdminAccess: boolean;
  authenticatedUserId: string;
}

const CenterAdminsTable: React.FC<CenterAdminsTableProps> = ({ hasSchoolAdminAccess, authenticatedUserId }) => {
  const { orgId } = useParams<OrgIdParamType>();

  const [centerAdminsState, setCenterAdminsState] = useState<CenterAdminsStateInterface>({
    admins: [],
    total: 0,
    isLoading: false,
    fetchDataOptions: initialFetchDataOptions
  });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getCenterAdmins = useCallback(
    debounce(async (fetchDataOptions: FetchDataOptions) => {
      setCenterAdminsState((state) => ({
        ...state,
        isLoading: true
      }));

      const { pageIndex, pageSize, filters, sortBy } = fetchDataOptions;
      const filtersQueryParams = getFiltersQueryParameters(filters);
      const sortingQueryParams = mapReactTableSortToApiSort(sortBy);

      try {
        const { data } = await apiService.get<UsersResponse>(`/org/${orgId}/users/center-admins`, {
          page: pageIndex,
          perPage: pageSize,
          ...filtersQueryParams,
          sortBy: sortingQueryParams
        });
        setCenterAdminsState((state) => ({
          ...state,
          admins: data.users,
          total: data.total,
          isLoading: false
        }));
      } catch {
        showNotification(false, 'Failed to load admins', true);
        setCenterAdminsState((state) => ({
          ...state,
          isLoading: false
        }));
      }
    }, 400),
    [orgId]
  );

  const deleteAdmin = async (orgId: string, userId: string, fetchDataOptions: FetchDataOptions) => {
    setConfirmationModalState((state) => {
      return { ...state, isOperating: true };
    });
    try {
      await apiService.delete<{ message: string }>(`/org/${orgId}/admins/${userId}`);
      getCenterAdmins(fetchDataOptions);
      setConfirmationModalState(defaultConfirmationModalState);
    } catch {
      showNotification(false, Messages.FailedDeleteAdministrator, true);
      setConfirmationModalState(defaultConfirmationModalState);
    }
  };
  const defaultConfirmationModalState = {
    isOpen: false,
    isOperating: false,
    message: '',
    onConfirm: null
  };
  const [confirmationModalState, setConfirmationModalState] = useState(defaultConfirmationModalState);

  const onDeleteCenterAdminClick = (
    orgId: string,
    userId: string,
    name: string,
    fetchDataOptions: FetchDataOptions
  ) => {
    setConfirmationModalState({
      isOpen: true,
      isOperating: false,
      message:
        `<div style="display:flex;justify-content:center">You are about to delete administrator ${name}.</div><br/>` +
        '<div style="display:flex;justify-content:center">Please, confirm.</div>',
      onConfirm: () => deleteAdmin(orgId, userId, fetchDataOptions)
    });
  };

  const [centerAdminUnderEdit, setUserUnderEdit] = useState(emptyCenterAdminUnderEdit);
  const onEditCenterAdminClick = (centerOrgId: string, userId: string, fetchDataOptions: FetchDataOptions) => {
    setUserUnderEdit({
      userId: userId,
      centerOrgId: centerOrgId,
      fetchDataOptions: fetchDataOptions
    });
  };
  const handleUserModalClose = async (reloadData: boolean) => {
    setUserUnderEdit((state) => {
      if (reloadData) {
        getCenterAdmins(state.fetchDataOptions);
      }
      return emptyCenterAdminUnderEdit;
    });
  };

  const columns = useCenterAdminsTableColumns(
    orgId,
    hasSchoolAdminAccess,
    onEditCenterAdminClick,
    onDeleteCenterAdminClick,
    authenticatedUserId
  );

  return (
    <div style={{ marginTop: '15px' }}>
      <Table
        columns={columns}
        data={centerAdminsState.admins}
        loading={centerAdminsState.isLoading}
        fetchData={getCenterAdmins}
        paginationType={PaginationTypes.ServerSide}
        pageCount={centerAdminsState.total}
        noDataText="No admins..."
      />
      {confirmationModalState.isOpen && (
        <ConfirmationModal
          open={confirmationModalState.isOpen}
          isOperating={confirmationModalState.isOperating}
          message={confirmationModalState.message}
          onClose={() => setConfirmationModalState(defaultConfirmationModalState)}
          onConfirm={() => confirmationModalState.onConfirm(confirmationModalState)}
        />
      )}
      {centerAdminUnderEdit.userId && (
        <UserEditModal
          open={!!centerAdminUnderEdit.userId}
          orgId={centerAdminUnderEdit.centerOrgId || orgId}
          userId={centerAdminUnderEdit.userId}
          onClose={handleUserModalClose}
        />
      )}
    </div>
  );
};

export default CenterAdminsTable;
