import { CountrySelect } from '@efacity/react-next-sc';
import { InputAdornment, TextField, TextFieldProps } from '@mui/material';
import { forwardRef } from 'react';
import { CountryCode } from '@efacity/common';

type InputComponentProps = {
  name: string;
  value: string;
  selectedCountry: CountryCode;
  errorMessage: string;
  isErrorShown: boolean;
  onChange: (phoneNumber: string) => void;
  onCountryChange: (country: string) => void;
  onBlur: () => void;
  allowChangeCountry?: boolean;
} & TextFieldProps;

export const PhoneInputComponent = forwardRef(
  (
    {
      name,
      value,
      selectedCountry,
      isErrorShown,
      errorMessage,
      onChange,
      onCountryChange,
      onBlur,
      allowChangeCountry,
      ...props
    }: InputComponentProps,
    ref
  ) => {
    return (
      <TextField
        color={props.color}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <CountrySelect
                value={selectedCountry}
                onCountryChange={onCountryChange}
                disabled={props?.disabled || false || !allowChangeCountry}
              />
            </InputAdornment>
          )
        }}
        {...props}
        inputRef={ref}
        id={name}
        name={name}
        value={value}
        error={isErrorShown}
        helperText={isErrorShown && errorMessage}
        fullWidth={true}
        onChange={onChange}
        size="small"
        variant="outlined"
        onBlur={onBlur}
        type={props.type}
      />
    );
  }
);

PhoneInputComponent.displayName = 'PhoneInput';
/*PhoneInputComponent.defaultProps = {
  label: 'Phone'
};*/
