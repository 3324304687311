import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { apiService } from '@efacity/frontend-shared';
import { showNotification } from '@efacity/frontend-next-shared/notifications';
import { Student } from '@efacity/common';
import Table, { FetchDataOptions, initialFetchDataOptions, PaginationTypes } from '../../components/Table/Table';
import useStudentsTableColumns from './useStudentsTableColumns';
import { OrgIdParamType } from '@efacity/frontend-next-shared/utils';
import EditStudentWithCustomerInfoModal, {
  StudentWithCustomerInfoTabs
} from '../../components/EditStudentWithCustomerInfoModal/EditStudentWithCustomerInfoModal';
import { getFiltersQueryParameters, mapReactTableSortToApiSort } from '../../utils/queryHelpers';
import { debounce } from 'lodash';
import NotesModal from '../../components/NotesModal/NotesModal';
import { PageTitle } from '@efacity/react-next-sc';

const emptyStudentUnderEdit = {
  studentId: '',
  studentName: '',
  fetchDataOptions: initialFetchDataOptions,
  tab: StudentWithCustomerInfoTabs.MAIN
};

const emptyStudentNoteModalState = { orgId: '', studentId: '', name: '' };

export interface StudentsStateInterface {
  students: Student[];
  total: number;
  isLoading: boolean;
  fetchDataOptions: FetchDataOptions;
}
export interface StudentsResponse {
  users: Student[];
  total?: number;
}

const OrganizationStudentsTable: React.FC = () => {
  const { orgId } = useParams<OrgIdParamType>();

  const [studentsState, setStudentsState] = useState<StudentsStateInterface>({
    students: [],
    total: 0,
    isLoading: false,
    fetchDataOptions: initialFetchDataOptions
  });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getStudents = useCallback(
    debounce(async (fetchDataOptions: FetchDataOptions) => {
      setStudentsState((state) => ({
        ...state,
        isLoading: true
      }));

      const { pageIndex, pageSize, filters, sortBy } = fetchDataOptions;
      const filtersQueryParams = getFiltersQueryParameters(filters);
      const sortingQueryParams = mapReactTableSortToApiSort(sortBy);

      try {
        const { data } = await apiService.get<StudentsResponse>(`/org/${orgId}/users/students`, {
          page: pageIndex,
          perPage: pageSize,
          ...filtersQueryParams,
          sortBy: sortingQueryParams
        });
        setStudentsState((state) => ({
          ...state,
          students: data.users,
          total: data.total,
          isLoading: false
        }));
      } catch {
        showNotification(false, 'Failed to load students', true);
        setStudentsState((state) => ({
          ...state,
          isLoading: false
        }));
      }
    }, 400),
    [orgId]
  );

  const [notesModalState, setNotesModalState] = useState(emptyStudentNoteModalState);
  const onOpenNotesModal = (studentId: string, name: string) => {
    setNotesModalState({ orgId: orgId, studentId: studentId, name: name });
  };
  const onCloseNotesModal = () => {
    setNotesModalState(emptyStudentNoteModalState);
  };

  const [studentUnderEdit, setStudentUnderEdit] = useState(emptyStudentUnderEdit);
  const handleEditStudentInfo = ({
    studentId,
    studentName,
    fetchDataOptions
  }: {
    studentId: string;
    studentName: string;
    fetchDataOptions: FetchDataOptions;
  }) => {
    setStudentUnderEdit({
      studentId: studentId,
      studentName: studentName,
      fetchDataOptions: fetchDataOptions,
      tab: StudentWithCustomerInfoTabs.MAIN
    });
  };
  const handleStudentModalClose = async (reloadData: boolean) => {
    setStudentUnderEdit((state) => {
      if (reloadData) {
        getStudents(state.fetchDataOptions);
      }
      return emptyStudentUnderEdit;
    });
  };

  const columns = useStudentsTableColumns(handleEditStudentInfo, onOpenNotesModal);

  return (
    <div>
      <PageTitle title="Students" addButtonLink={undefined} />
      <Table
        columns={columns}
        data={studentsState.students}
        loading={studentsState.isLoading}
        fetchData={getStudents}
        paginationType={PaginationTypes.ServerSide}
        pageCount={studentsState.total}
        noDataText="No students..."
      />
      {studentUnderEdit.studentId && (
        <EditStudentWithCustomerInfoModal
          open={!!studentUnderEdit.studentId}
          orgId={orgId}
          studentId={studentUnderEdit.studentId}
          studentName={studentUnderEdit.studentName}
          onClose={handleStudentModalClose}
          tab={studentUnderEdit.tab}
          setTab={(newTab: StudentWithCustomerInfoTabs) => setStudentUnderEdit((state) => ({ ...state, tab: newTab }))}
        />
      )}
      {notesModalState.studentId && (
        <NotesModal
          orgId={notesModalState.orgId}
          customerId={notesModalState.studentId}
          name={notesModalState.name}
          url={`/org/${orgId}/users/students/${notesModalState.studentId}/notes`}
          onClose={onCloseNotesModal}
        />
      )}
    </div>
  );
};

export default OrganizationStudentsTable;
