import { Box, debounce, Grid, Tooltip, Typography } from '@mui/material';
import React, { FC, useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Table, { FetchDataOptions, initialFetchDataOptions, PaginationTypes } from '../../components/Table/Table';
import { OrgIdParamType } from '@efacity/frontend-next-shared/utils';
import { apiService } from '@efacity/frontend-shared';
import { PATHS, toPath } from '@efacity/routing';
import { InstanceTemporality, Messages, RegistrationForOrganizationAdmin } from '@efacity/common';
import { getFiltersQueryParameters, mapReactTableSortToApiSort } from '../../utils/queryHelpers';
import { useOrganizationRegistrationsColumns } from './useOrganizationRegistrationsColumns';
import CancellationModal, { CancellationModalProps } from '../SessionsContainer/CancellationModal';
import SelectSessionModal from './SelectSessionModal';
import { handleSaveFile } from '../../utils/handleSaveFileFromResponse';
import { ExportButton } from '../../components/Buttons/ExportButton';
import NotesModal from '../../components/NotesModal/NotesModal';
import EditCustomerModal from '../../components/EditCustomerModal/EditCustomerModal';
import EditStudentWithCustomerInfoModal, {
  StudentWithCustomerInfoTabs
} from '../../components/EditStudentWithCustomerInfoModal/EditStudentWithCustomerInfoModal';
import removeDateOffsetFromFilters from '../../utils/removeDateOffsetFromFilters';
import { showNotification } from '@efacity/frontend-next-shared/notifications';

interface TableData {
  data: RegistrationForOrganizationAdmin[];
  total: number;
}

const emptyRegistrationToCancel = {
  registrationId: null,
  switchRegistration: false,
  cancellationAmount: '',
  studentId: '',
  sessionName: '',
  fetchDataOptions: initialFetchDataOptions
};

interface AdminRegistrationsTableProps {
  registrationType: InstanceTemporality;
}

const emptyStudentUnderEdit = {
  studentId: '',
  studentName: '',
  fetchDataOptions: initialFetchDataOptions,
  tab: StudentWithCustomerInfoTabs.MAIN
};
const emptyCustomerUnderEdit = {
  customerId: '',
  fetchDataOptions: initialFetchDataOptions
};

const emptyCustomerModalState = { orgId: '', customerId: '', name: '', url: '' };

const OrganizationRegistrationsTable: FC<AdminRegistrationsTableProps> = ({ registrationType }) => {
  const { orgId } = useParams<OrgIdParamType>();
  const navigate = useNavigate();
  const [tableData, setTableData] = useState<TableData>({
    data: [],
    total: 0
  });
  const [tableDataLoading, setTableDataLoading] = useState(false);
  const [isRegistering, setIsRegistering] = useState(false);
  const [registrationCancelling, setRegistrationCancelling] = useState(false);
  const [selectSessionModalOpen, setSelectSessionModalOpen] = useState(false);
  const [switchRegistrationCustomer, setSwitchRegistrationCustomer] = useState(null);
  const [registrationToCancel, setRegistrationToCancel] = useState<{
    registrationId: string;
    switchRegistration: boolean;
    cancellationAmount: string;
    studentId: string;
    sessionName: string;
    fetchDataOptions: FetchDataOptions;
  }>(emptyRegistrationToCancel);

  const [notesModalState, setNotesModalState] = useState(emptyCustomerModalState);
  const onOpenNotesModal = (customerId: string, name: string, url: string) => {
    setNotesModalState({ orgId: orgId, customerId: customerId, name: name, url: url });
  };
  const onCloseNotesModal = () => {
    setNotesModalState(emptyCustomerModalState);
  };

  const handleOpenCancellationModal = async (
    registrationId: string,
    studentId: string,
    sessionName: string,
    switchRegistration: boolean,
    cancellationAmount: string,
    fetchDataOptions: FetchDataOptions
  ) => {
    setRegistrationToCancel({
      registrationId,
      switchRegistration,
      cancellationAmount,
      studentId,
      sessionName,
      fetchDataOptions
    });
  };

  const [studentUnderEdit, setStudentUnderEdit] = useState(emptyStudentUnderEdit);
  const [customerUnderEdit, setCustomerUnderEdit] = useState(emptyCustomerUnderEdit);

  const columns = useOrganizationRegistrationsColumns(
    handleOpenCancellationModal,
    onOpenNotesModal,
    orgId,
    registrationCancelling || isRegistering,
    setStudentUnderEdit,
    setCustomerUnderEdit
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getDataDebounced = useCallback(
    debounce(
      async ({ filters, pageIndex, pageSize, sortBy }: FetchDataOptions, registrationType: InstanceTemporality) => {
        try {
          const { data } = await apiService.get<TableData>(`/org/${orgId}/registrations`, {
            page: pageIndex,
            perPage: pageSize,
            ...getFiltersQueryParameters(filters),
            sortBy: mapReactTableSortToApiSort(sortBy || []),
            registrationType: registrationType
          });
          setTableData(data);
          setTableDataLoading(false);
        } catch (error) {
          showNotification(false, error.response?.data?.message || Messages.FailedGetRegistrations);
        }
      },
      400
    ),
    []
  );

  const getRegistrations = useCallback(
    (fetchDataOptions: FetchDataOptions) => {
      setRegistrationToCancel((registrationToCancel) => {
        return {
          ...registrationToCancel,
          fetchDataOptions: fetchDataOptions
        };
      });
      setTableDataLoading(true);
      const { filters } = fetchDataOptions;
      const dateFilters = [
        'efacitySessionId.schedule.startDateTime',
        'efacitySessionId.schedule.endDateTime',
        'registeredAt'
      ];
      removeDateOffsetFromFilters(filters, dateFilters);
      getDataDebounced(fetchDataOptions, registrationType);
    },
    [getDataDebounced, registrationType]
  );

  const handleCancelRegistration: CancellationModalProps['submitCallback'] = (cancellationReason, submitActions) => {
    setRegistrationCancelling(true);
    const { registrationId, switchRegistration, fetchDataOptions } = registrationToCancel;

    apiService.patch(`/org/${orgId}/registrations/${registrationId}/cancel`, { cancellationReason }).then(
      async (result: any) => {
        setRegistrationCancelling(false);
        if (result.status === 202) {
          showNotification(true, result.data.message as string, true, 'Ok');
        }
        const { customerId } = result.data;
        setSwitchRegistrationCustomer(customerId);
        await getRegistrations(fetchDataOptions);
        setRegistrationToCancel({
          ...registrationToCancel,
          registrationId: null,
          fetchDataOptions: fetchDataOptions
        });
        if (switchRegistration) {
          setSelectSessionModalOpen(true);
        }
      },
      (error) => {
        setRegistrationCancelling(false);
        setSwitchRegistrationCustomer(null);
        if (error.response?.data?.validationErrors) {
          return submitActions.setValidationError(error.response?.data?.validationErrors.cancellationReason);
        }
        showNotification(false, (error.response.data.message as string) || Messages.FailedCancelRegistration, true);
      }
    );
  };

  const handleSessionToRegistrationSelected = async (sessionId: string) => {
    const { studentId } = registrationToCancel;
    setIsRegistering(true);
    apiService
      .post(`/org/${orgId}/registrations/${sessionId}/student/${studentId}/customer/${switchRegistrationCustomer}`, {})
      .then(
        async (result: any) => {
          setIsRegistering(false);
          setSelectSessionModalOpen(false);
          if (result.status === 202) {
            showNotification(false, result.data.message as string, true, 'Ok');
          }
          setRegistrationToCancel(emptyRegistrationToCancel);
          if (result.data?.invoiceId) {
            navigate(toPath(PATHS.invoiceAdditionalOptions, { orgId: orgId, invoiceId: result.data?.invoiceId }));
          }
        },
        (error) => {
          setIsRegistering(false);
          setSelectSessionModalOpen(false);
          setRegistrationToCancel(emptyRegistrationToCancel);
          showNotification(false, (error.response.data.message as string) || Messages.FailedRegisteredOnSession, true);
        }
      );
  };

  const handleCancellationModalClose = () => {
    setRegistrationToCancel(emptyRegistrationToCancel);
  };
  const handleSelectSessionModalClose = () => {
    setSelectSessionModalOpen(false);
  };

  const [isExporting, setIsExporting] = useState(false);
  const exportOrgRegistrations = () => {
    setIsExporting(true);
    const { fetchDataOptions } = registrationToCancel;
    const { filters, pageIndex, pageSize, sortBy } = fetchDataOptions;

    apiService
      .get(
        `/org/${orgId}/registrations/export`,
        {
          page: pageIndex,
          perPage: pageSize,
          ...getFiltersQueryParameters(filters),
          sortBy: mapReactTableSortToApiSort(sortBy || []),
          registrationType: registrationType
        },
        {
          'content-disposition': 'attachment'
        }
      )
      .then(
        async (result: any) => {
          setIsExporting(false);
          const contentTypeHeader = result.headers['content-type'];
          if (
            contentTypeHeader?.indexOf('text/csv') !== -1 ||
            contentTypeHeader?.indexOf('application/pdf') !== -1 ||
            contentTypeHeader?.indexOf('application/octet-stream') !== -1 ||
            contentTypeHeader?.indexOf('text/html') !== -1
          ) {
            handleSaveFile(result);
          } else {
            showNotification(false, Messages.UnsupportedFileType, true);
          }
        },
        (error) => {
          setIsExporting(false);
          showNotification(false, (error.response.data.message as string) || Messages.FailedExportRegistrations, true);
        }
      );
  };

  const handleCloseStudentEditModal = async (reloadData: boolean) => {
    setStudentUnderEdit((state) => {
      if (reloadData) {
        getRegistrations(state.fetchDataOptions);
      }
      return emptyStudentUnderEdit;
    });
  };
  const handleCustomerModalClose = async (reloadData: boolean) => {
    setCustomerUnderEdit((state) => {
      if (reloadData) {
        getRegistrations(state.fetchDataOptions);
      }
      return emptyCustomerUnderEdit;
    });
  };

  return (
    <Box>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Tooltip title="Only 1000 first records will be exported">
          <div>
            <ExportButton isExporting={isExporting} color={'primary'} onClick={exportOrgRegistrations} />
          </div>
        </Tooltip>
      </div>
      <Box paddingY={2}>
        <Table
          data={tableData.data}
          fetchData={getRegistrations}
          loading={tableDataLoading}
          columns={columns}
          pageCount={tableData.total}
          paginationType={PaginationTypes.ServerSide}
        />
        {registrationToCancel?.registrationId && (
          <CancellationModal
            titleText={
              registrationToCancel.switchRegistration ? 'Switch registration' : 'Enter registration cancellation reason'
            }
            message={
              registrationToCancel.switchRegistration ? (
                <div>
                  <Grid container spacing={1} style={{ marginTop: 15 }}>
                    <Grid item xs={4} style={{ textAlign: 'right' }}>
                      Cancelled Session:
                    </Grid>
                    <Grid item xs={8}>
                      <Typography color={'primary'} style={{ fontWeight: 700 }}>
                        {registrationToCancel.sessionName}
                      </Typography>
                    </Grid>
                    <Grid item xs={4} style={{ textAlign: 'right' }}>
                      Credit to be added:
                    </Grid>
                    <Grid item xs={8}>
                      <Typography color={'primary'} style={{ fontWeight: 700 }}>
                        {registrationToCancel.cancellationAmount}
                      </Typography>
                    </Grid>
                  </Grid>
                  <br />
                  Enter cancellation reason.
                </div>
              ) : null
            }
            open={!!registrationToCancel.registrationId}
            isLoading={registrationCancelling}
            submitCallback={handleCancelRegistration}
            onClose={handleCancellationModalClose}
          />
        )}
        {selectSessionModalOpen && (
          <SelectSessionModal
            open={selectSessionModalOpen}
            orgId={orgId}
            studentId={registrationToCancel.studentId}
            onClose={handleSelectSessionModalClose}
            onSessionSelected={handleSessionToRegistrationSelected}
            isRegistering={isRegistering}
          />
        )}
        {studentUnderEdit.studentId && (
          <EditStudentWithCustomerInfoModal
            orgId={orgId}
            studentId={studentUnderEdit.studentId}
            studentName={studentUnderEdit.studentName}
            onClose={handleCloseStudentEditModal}
            open={!!studentUnderEdit.studentId}
            tab={studentUnderEdit.tab}
            setTab={(newTab: StudentWithCustomerInfoTabs) =>
              setStudentUnderEdit((state) => ({ ...state, tab: newTab }))
            }
          />
        )}
        {customerUnderEdit.customerId && (
          <EditCustomerModal
            orgId={orgId}
            customerId={customerUnderEdit.customerId}
            onClose={handleCustomerModalClose}
          />
        )}
      </Box>
      {notesModalState.customerId && (
        <NotesModal
          orgId={notesModalState.orgId}
          customerId={notesModalState.customerId}
          name={notesModalState.name}
          url={notesModalState.url}
          onClose={onCloseNotesModal}
        />
      )}
    </Box>
  );
};

export default OrganizationRegistrationsTable;
